import { Chip, useTheme } from '@mui/material'

export default function CustomChip({ color: passedColor = 'default', ...rest }) {
  const theme = useTheme()

  const colorSolver = () => {
    switch (passedColor) {
      case 'default':
        return {
          color: theme.palette.text.primary,
          borderColor: theme.palette.divider,
        }
      case 'primary':
        return {
          color: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        }
      case 'secondary':
        return {
          color: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        }
      case 'error':
        return {
          color: theme.palette.error.main,
          borderColor: theme.palette.error.main,
        }
      case 'warning':
        return {
          color: theme.palette.warning.main,
          borderColor: theme.palette.warning.main,
        }
      case 'info':
        return {
          color: theme.palette.info.main,
          borderColor: theme.palette.info.main,
        }
      case 'success':
        return {
          color: theme.palette.success.main,
          borderColor: theme.palette.success.main,
        }
      default:
        return {
          color: passedColor ?? theme.palette.text.primary,
          borderColor: passedColor ?? theme.palette.divider,
        }
    }
  }

  const { color, borderColor } = colorSolver()

  return (
    <Chip
      {...rest}
      sx={{
        '&.MuiChip-outlined': {
          borderColor,
          color,
        },
        '&.MuiChip-filled': {
          backgroundColor: color,
          color: theme.palette.getContrastText(color),
        },
      }}
    />
  )
}
