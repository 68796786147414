import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { LANGUAGE } from 'config'

import bgBG from './files/bg.json'
import enUS from './files/en.json'
import errorsBG from './files/errorsBG.json'
import errorsEN from './files/errorsEN.json'
import authBG from './files/authBG.json'
import authEN from './files/authEN.json'
import usersBG from './files/usersBG.json'
import usersEN from './files/usersEN.json'
import tenantsBG from './files/tenantsBG.json'
import tenantsEN from './files/tenantsEN.json'
import reservationsBG from './files/reservationsBG.json'
import reservationsEN from './files/reservationsEN.json'
import profileBG from './files/profileBG.json'
import profileEN from './files/profileEN.json'
import productsBG from './files/productsBG.json'
import productsEN from './files/productsEN.json'
import financesBG from './files/financesBG.json'
import financesEN from './files/financesEN.json'
import escaperoomsBG from './files/escaperoomsBG.json'
import escaperoomsEN from './files/escaperoomsEN.json'
import giftcardsBG from './files/giftcardsBG.json'
import giftcardsEN from './files/giftcardsEN.json'
import invoicesBG from './files/invoicesBG.json'
import invoicesEN from './files/invoicesEN.json'
import tasksBG from './files/tasksBG.json'
import tasksEN from './files/tasksEN.json'
import paymentsBG from './files/paymentsBG.json'
import paymentsEN from './files/paymentsEN.json'
import systemBG from './files/systemBG.json'
import systemEN from './files/systemEN.json'
import chatBG from './files/chatBG.json'
import chatEN from './files/chatEN.json'
import notesBG from './files/notesBG.json'
import notesEN from './files/notesEN.json'
import subscriptionBG from './files/subscriptionBG.json'
import subscriptionEN from './files/subscriptionEN.json'
import chartsBG from './files/chartsBG.json'
import chartsEN from './files/chartsEN.json'
import photosBG from './files/photosBG.json'
import photosEN from './files/photosEN.json'
import pocketBG from './files/pocketBG.json'
import pocketEN from './files/pocketEN.json'
import emailTemplatesBG from './files/emailTemplatesBG.json'
import emailTemplatesEN from './files/emailTemplatesEN.json'

const resources = {
  enUS: {
    translation: enUS,
    auth: authEN,
    errors: errorsEN,
    users: usersEN,
    tenants: tenantsEN,
    reservations: reservationsEN,
    profile: profileEN,
    products: productsEN,
    finances: financesEN,
    escaperooms: escaperoomsEN,
    giftcards: giftcardsEN,
    invoices: invoicesEN,
    tasks: tasksEN,
    payments: paymentsEN,
    photos: photosEN,
    system: systemEN,
    chat: chatEN,
    notes: notesEN,
    subscription: subscriptionEN,
    charts: chartsEN,
    pocket: pocketEN,
    emailTemplates: emailTemplatesEN,
  },
  enGB: {
    translation: enUS,
    auth: authEN,
    errors: errorsEN,
    users: usersEN,
    tenants: tenantsEN,
    reservations: reservationsEN,
    profile: profileEN,
    products: productsEN,
    finances: financesEN,
    escaperooms: escaperoomsEN,
    giftcards: giftcardsEN,
    invoices: invoicesEN,
    tasks: tasksEN,
    payments: paymentsEN,
    photos: photosEN,
    system: systemEN,
    chat: chatEN,
    notes: notesEN,
    subscription: subscriptionEN,
    charts: chartsEN,
    pocket: pocketEN,
    emailTemplates: emailTemplatesEN,
  },
  bgBG: {
    translation: bgBG,
    auth: authBG,
    errors: errorsBG,
    users: usersBG,
    tenants: tenantsBG,
    reservations: reservationsBG,
    profile: profileBG,
    products: productsBG,
    finances: financesBG,
    escaperooms: escaperoomsBG,
    giftcards: giftcardsBG,
    invoices: invoicesBG,
    tasks: tasksBG,
    payments: paymentsBG,
    photos: photosBG,
    system: systemBG,
    chat: chatBG,
    notes: notesBG,
    subscription: subscriptionBG,
    charts: chartsBG,
    pocket: pocketBG,
    emailTemplates: emailTemplatesBG,
  },
}

export const languages = {
  enUS: {
    localeLanguage: 'English (USA)',
    shortName: 'en',
    fullName: 'enUS',
    transformed: 'en-US',
  },
  enGB: {
    localeLanguage: 'English (UK)',
    shortName: 'en-GB',
    fullName: 'enGB',
    transformed: 'en-GB',
  },
  bgBG: {
    localeLanguage: 'Български',
    shortName: 'bg',
    fullName: 'bgBG',
    transformed: 'bg-BG',
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || LANGUAGE,
  keySeparator: false,
  fallbackNS: 'enGB',
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
