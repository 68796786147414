import { Button, InputAdornment, MenuItem, Stack, TextField, Typography } from '@mui/material'
import { useDate, useEscapeRooms, useTenantInfo } from 'core'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { playersRange } from 'utils'
import { API_URL } from 'config'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useError, usePricing } from 'utils/hooks'
import Loading from 'views/Loading'
import Summary from '../Summary'
import { PAYMENT_METHODS } from 'config'

export default function Pricing({
  reservation,
  code,
  cart,
  setCode,
  total,
  setTotal,
  isLoading,
  setIsLoading,
  paymentMethod,
  selectedPlayers,
  setPaymentMethod,
  openPlayedDialog,
  setSelectedPlayers,
}) {
  const { t } = useTranslation('reservations')
  const { escapeRooms } = useEscapeRooms()
  const { activeTenantFullInfo } = useTenantInfo()
  const { currentTimeInMillis } = useDate()
  const { setError } = useError()
  const { enqueueSnackbar } = useSnackbar()

  const escapeRoom = escapeRooms.find(({ id }) => id === reservation.key.roomId)

  const [isValid, setIsValid] = useState(true)
  const [isValidateLoading, setIsValidateLoading] = useState(false)
  const [giftCard, setGiftCard] = useState(null)

  const { balanceDue, subtotal, validProducts, selectedGameMode, existingFinances } = usePricing({
    cart,
    reservation,
    giftCard,
    numberOfPlayers: selectedPlayers,
  })

  const checkGiftCard = useCallback(async () => {
    setIsValidateLoading(true)
    try {
      const { data: responseGiftCard } = await axios(`${API_URL}/voucher/byCode/${code}/${escapeRoom.tenantId}`)

      if (
        responseGiftCard.timesUsed === responseGiftCard.useLimit ||
        (responseGiftCard.validUntil && responseGiftCard.validUntil < currentTimeInMillis)
      ) {
        setIsValid(false)
      } else {
        setIsValid(true)
        setGiftCard(responseGiftCard)
        setTotal(balanceDue)
      }
      setIsLoading(false)
      setIsValidateLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
      setIsValid(false)
      setGiftCard(null)
      setIsValidateLoading(false)
    }
  }, [code, currentTimeInMillis, escapeRoom.tenantId, balanceDue, setError, setIsLoading, setTotal])

  const validateCode = useCallback(async () => {
    setIsValidateLoading(true)
    setTotal(balanceDue)
    try {
      const { data: responseGiftCard } = await axios(`${API_URL}/voucher/byCode/${code}/${escapeRoom.tenantId}`)

      if (
        responseGiftCard.timesUsed === responseGiftCard.useLimit ||
        (responseGiftCard.validUntil && responseGiftCard.validUntil < currentTimeInMillis)
      ) {
        if (code !== '') {
          setIsValid(false)
        } else {
          setIsValid(true)
        }
      } else {
        setIsValid(true)
        setGiftCard(responseGiftCard)
        enqueueSnackbar(t('GiftCardIsValid'), { variant: 'success' })
      }
      setTotal(balanceDue)
      setIsValidateLoading(false)
    } catch (error) {
      if (code !== '') {
        setIsValid(false)
      } else {
        setIsValid(true)
      }
      setGiftCard(null)
      setIsValidateLoading(false)
      setError(error)
      setTotal(total)
    }
  }, [code, currentTimeInMillis, enqueueSnackbar, escapeRoom.tenantId, balanceDue, setError, setTotal, t, total])

  useEffect(() => {
    reservation.discountCode !== null && openPlayedDialog && checkGiftCard()
    reservation.discountCode === null && setIsLoading(false)
  }, [checkGiftCard, openPlayedDialog, reservation.discountCode, setIsLoading])

  useEffect(() => {
    if (giftCard) {
      if (
        giftCard.timesUsed === giftCard.useLimit ||
        (giftCard.validUntil && giftCard.validUntil < currentTimeInMillis)
      ) {
        if (code !== '') {
          setIsValid(false)
        } else {
          setIsValid(true)
        }
      } else {
        setIsValid(true)
        setGiftCard(giftCard)
      }
    }
    setTotal(balanceDue)
    // eslint-disable-next-line
  }, [reservation, escapeRoom, giftCard, selectedPlayers, balanceDue])

  return !isLoading ? (
    <Stack gap={1.5}>
      <TextField
        sx={{ marginTop: 2 }}
        fullWidth
        variant="outlined"
        size="small"
        label={t('Players')}
        onChange={(e) => setSelectedPlayers(e.target.value)}
        value={selectedPlayers}
        select
      >
        {playersRange(escapeRoom.gameplayInfo.minPlayers, escapeRoom.gameplayInfo.maxPlayers).map((item) => (
          <MenuItem key={item} value={item}>
            {t('XPeople', { x: item })}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        value={total}
        type="number"
        onChange={(e) => setTotal(e.target.value)}
        label={t('PaidAmount')}
        size="small"
        InputProps={{
          endAdornment: <InputAdornment position="end">{activeTenantFullInfo?.currency}</InputAdornment>,
        }}
      />
      <TextField
        fullWidth
        label={t('finances:PaymentMethod')}
        onChange={(e) => setPaymentMethod(e.target.value)}
        value={paymentMethod}
        size="small"
        inputProps={{
          'aria-label': 'payment method',
        }}
        select
      >
        {Object.values(PAYMENT_METHODS).map(({ key, value }) => (
          <MenuItem key={key} value={key}>
            {t(`finances:${value}`)}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        fullWidth
        value={code.toUpperCase()}
        onChange={(e) => setCode(e.target.value.toUpperCase())}
        label={t('Discount')}
        size="small"
        helperText={!isValid && t('GiftCardNotValid')}
        error={!isValid}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                size="small"
                variant="outlined"
                color="success"
                disabled={isValidateLoading}
                onClick={validateCode}
              >
                {t('translation:Apply')}
              </Button>
            </InputAdornment>
          ),
        }}
      />

      <Summary
        cart={cart}
        subtotal={subtotal}
        escapeRoom={escapeRoom}
        giftCard={giftCard}
        isValidateLoading={isValidateLoading}
        selectedPlayers={selectedPlayers}
        validProducts={validProducts}
        selectedGameMode={selectedGameMode}
        balanceDue={balanceDue}
        existingFinances={existingFinances}
        reservation={reservation}
      />

      <Typography variant="caption" color="textSecondary" sx={{ lineHeight: '12px' }}>
        {t('PlayedReservationNote')}
      </Typography>
    </Stack>
  ) : (
    <Loading height="auto" />
  )
}
