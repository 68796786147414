import { ROLES } from 'config'
import Loading from 'views/Loading'
import { sideBarVisibilityRestrictions } from 'utils'
import { Suspense, lazy, useEffect, Fragment } from 'react'
import { useAuth, useTenantInfo, useEscapeRooms, useSubscription } from 'core'
import { Main as MainLayout, MainSuspense as MainSuspenseLayout } from 'layouts'
import { ACCEPTED_GROUPS, ACCEPTED_ADMIN_GROUPS, SUBSCRIPTION_STATUSES } from 'config'
import { Navigate, Route, Routes as RouterRoutes, useLocation } from 'react-router-dom'

import DnsIcon from '@mui/icons-material/Dns'
import RoomTwoToneIcon from '@mui/icons-material/RoomTwoTone'
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone'
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone'
import PaymentTwoToneIcon from '@mui/icons-material/PaymentTwoTone'
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone'
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone'
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone'
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone'
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone'
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone'
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone'
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone'
import CardGiftcardTwoToneIcon from '@mui/icons-material/CardGiftcardTwoTone'
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone'
import FilterFramesTwoToneIcon from '@mui/icons-material/FilterFramesTwoTone'
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone'
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone'
import DeveloperBoardTwoToneIcon from '@mui/icons-material/DeveloperBoardTwoTone'
import SettingsApplicationsTwoToneIcon from '@mui/icons-material/SettingsApplicationsTwoTone'

const UserView = lazy(() => import('views/User'))
const ChatView = lazy(() => import('views/Chat'))
const NoteView = lazy(() => import('views/Note'))
const UsersView = lazy(() => import('views/Users'))
const NotesView = lazy(() => import('views/Notes'))
const TasksView = lazy(() => import('views/Tasks'))
const PhotosView = lazy(() => import('views/Photos'))
const TenantView = lazy(() => import('views/Tenant'))
const WaiverView = lazy(() => import('views/Waiver'))
const SystemView = lazy(() => import('views/System'))
const ProductView = lazy(() => import('views/Product'))
const ProfileView = lazy(() => import('views/Profile'))
const TenantsView = lazy(() => import('views/Tenants'))
const RegisterView = lazy(() => import('views/RegisterGameMaster'))
const CalendarView = lazy(() => import('views/Calendar'))
const FinancesView = lazy(() => import('views/Finances'))
const InvoicesView = lazy(() => import('views/Invoices'))
const ProductsView = lazy(() => import('views/Products'))
const NotFoundView = lazy(() => import('views/NotFound'))
const ActivateView = lazy(() => import('views/Activate'))
const ChangelogView = lazy(() => import('views/Changelog'))
const DashboardView = lazy(() => import('views/Dashboard'))
const GiftCardsView = lazy(() => import('views/GiftCards'))
const EmployeesView = lazy(() => import('views/Employees'))
const PhotoFrameView = lazy(() => import('views/PhotoFrame'))
const EscapeRoomView = lazy(() => import('views/EscapeRoom'))
const EscapeRoomsView = lazy(() => import('views/EscapeRooms'))
const ReservationView = lazy(() => import('views/Reservation'))
const PhotoFramesView = lazy(() => import('views/PhotoFrames'))
const SubscriptionView = lazy(() => import('views/Subscription'))
const ReservationsView = lazy(() => import('views/Reservations'))
const PaymentIssueView = lazy(() => import('views/PaymentIssue'))
const ResetPasswordView = lazy(() => import('views/ResetPassword'))
const SubscriptionsView = lazy(() => import('views/Subscriptions'))
const EmailTemplateView = lazy(() => import('views/EmailTemplate'))
const EmailTemplatesView = lazy(() => import('views/EmailTemplates'))
const TenantSettingsView = lazy(() => import('views/TenantSettings'))
const AuthenticationView = lazy(() => import('views/Authentication'))
const InstallationGuideView = lazy(() => import('views/InstallationGuide'))
const ForgottenPasswordView = lazy(() => import('views/ForgottenPassword'))
const InvoiceView = lazy(() => import('views/Invoices/components/Invoice'))

export const PageURLs = {
  Auth: '/auth',
  Chat: '/chat',
  Dashboard: '/',
  Tasks: '/tasks',
  Users: '/users',
  Notes: '/notes',
  System: '/system',
  Waiver: '/waiver',
  Photos: '/photos',
  Tenants: '/tenants',
  Profile: '/profile',
  Register: '/sign-up',
  Activate: '/activate',
  Calendar: '/calendar',
  Invoices: '/invoices',
  Finances: '/finances',
  Products: '/products',
  ResetPassword: '/reset',
  Changelog: '/changelog',
  Employees: '/employees',
  GiftCards: '/gift-cards',
  MyInvoices: 'my-invoices',
  TenantSettings: '/company',
  General: '/profile/general',
  Billing: '/profile/billing',
  EscapeRooms: '/escape-rooms',
  PhotoFrames: '/photo-frames',
  Reservations: '/reservations',
  PaymentIssue: '/payment-issue',
  Subscriptions: '/subscriptions',
  EmailTemplates: '/email-templates',
  Preferences: '/profile/preferences',
  Notifications: '/profile/notifications',
  InstallationGuide: '/installation-guide',
  ForgottenPassword: '/forgotten-password',
  ActiveSessions: '/profile/active-sessions',
  ChangePassword: '/profile/change-password',
}

const { HAS_SELECTED_ESCAPE_ROOM } = sideBarVisibilityRestrictions

export const sidebarLinks = [
  {
    title: 'EscapeRoom',
    visibleTo: ACCEPTED_GROUPS,
    visibilityRestrictions: [HAS_SELECTED_ESCAPE_ROOM],
    links: [
      {
        name: 'Dashboard',
        to: PageURLs.Dashboard,
        icon: AssessmentTwoToneIcon,
        exact: true,
        acceptedRoles: [ROLES.EMPLOYEE.key, ROLES.OWNER.key, ROLES.READ_ONLY.key],
      },
      {
        name: 'Calendar',
        to: PageURLs.Calendar,
        icon: DateRangeTwoToneIcon,
        acceptedRoles: [ROLES.EMPLOYEE.key, ROLES.OWNER.key],
      },
      {
        name: 'Reservations',
        to: PageURLs.Reservations,
        icon: BookmarkTwoToneIcon,
        acceptedRoles: [ROLES.EMPLOYEE.key, ROLES.OWNER.key],
      },
      {
        name: 'Finances',
        to: PageURLs.Finances,
        icon: MonetizationOnTwoToneIcon,
        acceptedRoles: [ROLES.EMPLOYEE.key, ROLES.OWNER.key],
      },
      {
        name: 'Photos',
        to: PageURLs.Photos,
        icon: PhotoCameraTwoToneIcon,
        acceptedRoles: [ROLES.EMPLOYEE.key, ROLES.OWNER.key],
      },
    ],
  },
  {
    title: 'Company',
    visibleTo: ACCEPTED_GROUPS,
    visibilityRestrictions: [HAS_SELECTED_ESCAPE_ROOM],
    links: [
      {
        name: 'EscapeRooms',
        to: PageURLs.EscapeRooms,
        icon: RoomTwoToneIcon,
        acceptedRoles: [ROLES.OWNER.key],
        exact: true,
      },
      {
        name: 'GiftCards',
        to: PageURLs.GiftCards,
        icon: CardGiftcardTwoToneIcon,
        acceptedRoles: [ROLES.OWNER.key, ROLES.EMPLOYEE.key, ROLES.READ_ONLY.key],
      },
      {
        name: 'Products',
        to: PageURLs.Products,
        icon: ShoppingCartTwoToneIcon,
        acceptedRoles: [ROLES.OWNER.key, ROLES.EMPLOYEE.key, ROLES.READ_ONLY.key],
      },
      {
        name: 'Employees',
        to: PageURLs.Employees,
        icon: PeopleAltTwoToneIcon,
        acceptedRoles: [ROLES.OWNER.key],
      },
      {
        name: 'PhotoFrames',
        to: PageURLs.PhotoFrames,
        icon: FilterFramesTwoToneIcon,
        acceptedRoles: [ROLES.OWNER.key, ROLES.EMPLOYEE.key],
      },
      {
        name: 'EmailTemplates',
        to: PageURLs.EmailTemplates,
        icon: MailTwoToneIcon,
        acceptedRoles: [ROLES.OWNER.key],
      },
      {
        name: 'Invoices',
        to: PageURLs.Invoices,
        icon: DescriptionTwoToneIcon,
        acceptedRoles: [ROLES.OWNER.key, ROLES.EMPLOYEE.key, ROLES.READ_ONLY.key],
      },
      {
        name: 'Settings',
        to: PageURLs.TenantSettings,
        icon: SettingsApplicationsTwoToneIcon,
        acceptedRoles: [ROLES.OWNER.key],
      },
    ],
  },
  {
    title: 'Other',
    visibleTo: ACCEPTED_GROUPS,
    visibilityRestrictions: [HAS_SELECTED_ESCAPE_ROOM],
    links: [
      {
        name: 'Tasks',
        to: PageURLs.Tasks,
        icon: DeveloperBoardTwoToneIcon,
        acceptedRoles: [ROLES.EMPLOYEE.key, ROLES.OWNER.key, ROLES.READ_ONLY.key],
      },
      {
        name: 'Notes',
        to: PageURLs.Notes,
        icon: TextSnippetTwoToneIcon,
        acceptedRoles: [ROLES.EMPLOYEE.key, ROLES.OWNER.key, ROLES.READ_ONLY.key],
      },
      {
        name: 'Chat',
        to: PageURLs.Chat,
        icon: ChatTwoToneIcon,
        acceptedRoles: [ROLES.EMPLOYEE.key, ROLES.OWNER.key, ROLES.READ_ONLY.key],
      },
    ],
  },
  {
    title: 'Management',
    visibleTo: ACCEPTED_ADMIN_GROUPS,
    visibilityRestrictions: [],
    links: [
      { name: 'Users', to: PageURLs.Users, icon: PersonOutlineTwoToneIcon },
      { name: 'Tenants', to: PageURLs.Tenants, icon: BusinessTwoToneIcon },
      { name: 'Subscriptions', to: PageURLs.Subscriptions, icon: PaymentTwoToneIcon },
      { name: 'System', to: PageURLs.System, icon: DnsIcon },
    ],
  },
]

const Routes = () => {
  const location = useLocation()
  const { isAuthenticated, loadingAuth, user, setLoggingIn, loggingIn } = useAuth()
  const { loadingTenants, tenants, activeTenant } = useTenantInfo()
  const { loadingEscapeRooms, escapeRooms } = useEscapeRooms()
  const { loadingSubscription, plan } = useSubscription()

  useEffect(() => {
    !loadingAuth &&
      !loadingTenants &&
      !loadingEscapeRooms &&
      !loadingSubscription &&
      !!tenants.length &&
      plan &&
      !!escapeRooms.length &&
      isAuthenticated &&
      setLoggingIn(false)

    ACCEPTED_ADMIN_GROUPS.includes(user?.userGroup) && setLoggingIn(false)
    // eslint-disable-next-line
  }, [
    loadingAuth,
    loadingTenants,
    loadingEscapeRooms,
    loadingSubscription,
    escapeRooms,
    plan,
    isAuthenticated,
    tenants,
    user,
  ])

  return !loadingAuth && !loadingTenants && !loadingEscapeRooms && !loadingSubscription ? (
    <Suspense fallback={isAuthenticated && tenants.length && escapeRooms.length ? <MainSuspenseLayout /> : <Loading />}>
      <RouterRoutes location={location}>
        <Route path={PageURLs.Auth} element={<AuthenticationView />} />
        <Route path={`${PageURLs.ResetPassword}/:email/:code`} element={<ResetPasswordView />} />
        <Route path={PageURLs.ForgottenPassword} element={<ForgottenPasswordView />} />
        <Route path={`${PageURLs.Activate}/:email/:code`} element={<ActivateView />} />
        <Route path={PageURLs.Register} element={<RegisterView />} />

        <Route
          path={PageURLs.PaymentIssue}
          element={
            <RequireAuth>
              <PaymentIssueView />
            </RequireAuth>
          }
        />

        {plan &&
          plan?.status !== SUBSCRIPTION_STATUSES['ACTIVE'].key &&
          isAuthenticated &&
          !ACCEPTED_ADMIN_GROUPS.includes(user.userGroup) && (
            <Route path="*" element={<Navigate to={PageURLs.PaymentIssue} />} />
          )}

        {(!tenants.length || !plan || !escapeRooms.length) &&
          isAuthenticated &&
          !ACCEPTED_ADMIN_GROUPS.includes(user?.userGroup) && (
            <Route path="*" element={<Navigate to={PageURLs.Register} />} />
          )}
        {!loggingIn && (ACCEPTED_ADMIN_GROUPS.includes(user?.userGroup) || activeTenant) ? (
          <Route
            index
            exact
            path={PageURLs.Dashboard}
            element={
              <RequireAuth>
                <DashboardView />
              </RequireAuth>
            }
          />
        ) : !isAuthenticated ? (
          <Route path="*" element={<Navigate to={PageURLs.Auth} />} />
        ) : (
          <Route path="*" element={<Loading />} />
        )}
        <Route
          exact
          path={PageURLs.Tasks}
          element={
            <RequireAuth>
              <TasksView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Chat}
          element={
            <RequireAuth>
              <ChatView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.InstallationGuide}
          element={
            <RequireAuth>
              <InstallationGuideView />
            </RequireAuth>
          }
        />
        <Route
          path={PageURLs.Notes}
          exact
          element={
            <RequireAuth>
              <NotesView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Notes}/create`}
          element={
            <RequireAuth>
              <NoteView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Notes}/:noteId`}
          element={
            <RequireAuth>
              <NoteView />
            </RequireAuth>
          }
        />
        <Route
          path={`${PageURLs.Waiver}/:roomId/:reservationId`}
          element={
            <RequireAuth layout={Fragment}>
              <WaiverView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Users}
          element={
            <RequireAuth accept={ACCEPTED_ADMIN_GROUPS}>
              <UsersView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Users}/:username`}
          element={
            <RequireAuth accept={ACCEPTED_ADMIN_GROUPS}>
              <UserView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Profile}
          element={
            <RequireAuth>
              <ProfileView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Profile}/:tab`}
          element={
            <RequireAuth>
              <ProfileView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Tenants}
          element={
            <RequireAuth accept={ACCEPTED_ADMIN_GROUPS}>
              <TenantsView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Subscriptions}
          element={
            <RequireAuth accept={ACCEPTED_ADMIN_GROUPS}>
              <SubscriptionsView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.System}
          element={
            <RequireAuth accept={ACCEPTED_ADMIN_GROUPS}>
              <SystemView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Tenants}/:tenantId`}
          element={
            <RequireAuth accept={ACCEPTED_ADMIN_GROUPS}>
              <TenantView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Subscriptions}/:tenantId`}
          element={
            <RequireAuth accept={ACCEPTED_ADMIN_GROUPS}>
              <SubscriptionView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Products}
          element={
            <RequireAuth>
              <ProductsView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Products}/:productId`}
          element={
            <RequireAuth>
              <ProductView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Products}/create`}
          element={
            <RequireAuth>
              <ProductView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Reservations}
          element={
            <RequireAuth>
              <ReservationsView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Reservations}/:roomId/:reservationId`}
          element={
            <RequireAuth>
              <ReservationView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Calendar}
          element={
            <RequireAuth>
              <CalendarView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Finances}
          element={
            <RequireAuth>
              <FinancesView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.EscapeRooms}
          element={
            <RequireAuth>
              <EscapeRoomsView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Employees}
          element={
            <RequireAuth>
              <EmployeesView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.GiftCards}
          element={
            <RequireAuth>
              <GiftCardsView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Photos}
          element={
            <RequireAuth>
              <PhotosView />
            </RequireAuth>
          }
        />
        <Route
          path={PageURLs.PhotoFrames}
          exact
          element={
            <RequireAuth>
              <PhotoFramesView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.PhotoFrames}/:photoFrameId`}
          element={
            <RequireAuth>
              <PhotoFrameView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.PhotoFrames}/create`}
          element={
            <RequireAuth>
              <PhotoFrameView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Invoices}
          element={
            <RequireAuth>
              <InvoicesView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Invoices}/:invoiceId`}
          element={
            <RequireAuth>
              <InvoiceView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.Invoices}/create`}
          element={
            <RequireAuth>
              <InvoiceView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.EmailTemplates}`}
          element={
            <RequireAuth>
              <EmailTemplatesView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.EmailTemplates}/:emailTemplateId`}
          element={
            <RequireAuth>
              <EmailTemplateView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.EmailTemplates}/create`}
          element={
            <RequireAuth>
              <EmailTemplateView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.EscapeRooms}/:escapeRoomId`}
          element={
            <RequireAuth>
              <EscapeRoomView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={`${PageURLs.EscapeRooms}/create`}
          element={
            <RequireAuth>
              <EscapeRoomView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.TenantSettings}
          element={
            <RequireAuth>
              <TenantSettingsView />
            </RequireAuth>
          }
        />
        <Route
          exact
          path={PageURLs.Changelog}
          element={
            <RequireAuth>
              <ChangelogView />
            </RequireAuth>
          }
        />
        <Route path="*" element={<NotFoundView />} />
      </RouterRoutes>
    </Suspense>
  ) : (
    <Loading />
  )
}

function RequireAuth(props) {
  const { accept = null, layout: Layout = MainLayout, children } = props
  const { isAuthenticated, user } = useAuth()

  const acceptedGroups = accept || ACCEPTED_GROUPS

  return isAuthenticated && user ? (
    acceptedGroups.includes(user?.userGroup) ? (
      <Layout>{children}</Layout>
    ) : (
      <Navigate to={PageURLs.Auth} />
    )
  ) : (
    <Navigate to={PageURLs.Auth} />
  )
}

export default Routes
