import { Alert, Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { ButtonTriggeredDialog, DisplayCurrency } from 'components'
import { useTranslation } from 'react-i18next'
import { PricingItem, Summary } from '../Status/components/PlayedReservationDialog/components'
import { transformOrderEntriesToCartObject } from 'utils'
import { useEscapeRooms, useTenantInfo } from 'core'
import { API_URL, FINANCE_TYPES, RESERVATION_STATUSES } from 'config'
import useSWR from 'swr'
import { usePricing } from 'utils/hooks'
import { ReceiptOutlined } from '@mui/icons-material'

export default function PaymentSummary({ order, reservation }) {
  const { t } = useTranslation('reservations')
  const { flattenedProducts } = useTenantInfo()
  const { escapeRooms } = useEscapeRooms()

  const escapeRoom = escapeRooms.find((escapeRoom) => escapeRoom.id === reservation.key.roomId)

  const { data: giftCard, isLoading: isValidateLoading } = useSWR(
    reservation.discountCode && `${API_URL}/voucher/byCode/${reservation.discountCode}/${escapeRoom.tenantId}`,
    { shouldRetryOnError: false }
  )

  const { data: finances, isLoading: isFinanceLoading } = useSWR(
    reservation?.status === RESERVATION_STATUSES.PLAYED.key &&
      `${API_URL}/finances/room/${reservation.key.roomId}/reservation/${reservation.key.reservationId}?financeType=${FINANCE_TYPES.INCOME.key}&financeType=${FINANCE_TYPES.EXPENSE.key}`,
    {
      shouldRetryOnError: false,
      fallbackData: [{ amount: 0 }],
    }
  )

  const cart = transformOrderEntriesToCartObject(order?.orderEntries ?? [], flattenedProducts)

  const { balanceDue, subtotal, validProducts, selectedGameMode, existingFinances } = usePricing({
    reservation,
    giftCard,
    numberOfPlayers: reservation.playerCount,
    cart,
  })

  const totalFinance = finances
    .map((finance) => (finance.financeType === FINANCE_TYPES.INCOME.key ? finance.amount : -finance.amount))
    .reduce((acc, amount) => acc + amount, 0)

  return (
    <ButtonTriggeredDialog
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      buttonComponent={MenuItem}
      dialogTitle={t('PaymentSummary')}
      secondaryActionText={t('translation:Close')}
      customActionButton={<></>}
      closeIcon
      buttonText={
        <>
          <ListItemIcon>
            <ReceiptOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('PaymentSummary')}</ListItemText>
        </>
      }
    >
      {reservation?.status === RESERVATION_STATUSES.PLAYED.key && (
        <Alert severity="info">{t('RemovedFullPaymentBecauseReservationIsPlayed')}</Alert>
      )}
      <Summary
        {...{
          balanceDue,
          subtotal,
          cart,
          escapeRoom,
          giftCard,
          isValidateLoading,
          selectedGameMode,
          validProducts,
          reservation,
          existingFinances,
          selectedPlayers: reservation.playerCount,
        }}
      />
      {reservation?.status === RESERVATION_STATUSES.PLAYED.key && (
        <>
          <Divider flexItem />
          <PricingItem label={t('AmountSavedInFinances')} bold>
            {!isFinanceLoading ? <DisplayCurrency number={totalFinance} /> : null}
          </PricingItem>
        </>
      )}
    </ButtonTriggeredDialog>
  )
}
