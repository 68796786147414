import axios from 'axios'
import { API_URL, EMAIL_TYPES } from 'config'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { resizeImage } from 'utils'
import { v4 as uuid } from 'uuid'
import useError from './useError'
import { File } from 'layouts/Main/components/UploadFiles/components'
import { useTranslation } from 'react-i18next'
import { getActiveEmailTemplateByRoomId } from 'core/contexts/TenantInfoContext/utils'
import { useEscapeRooms, useTenantInfo } from 'core'
import { queryParamAsArray } from 'helpers'
import { MAX_FILES } from 'views/Calendar/components/Reservations/components/Reservation/components/Status/components/PlayedReservationDialog/PlayedReservationDialog'

const useSendEmail = ({
  tenantId,
  roomId,
  callbackFn = async () => {},
  languageTag,
  format = 'jpg',
  maxFiles = MAX_FILES,
  recipients = [],
  url = `${API_URL}/mail/send`,
  instantRevoke = false,
  reservation,
  keepAlreadyUploadedFiles = false,
  emailType = EMAIL_TYPES.POST_GAME.key,
}) => {
  const { t } = useTranslation('emailTemplates')
  const { setError } = useError()
  const { enqueueSnackbar } = useSnackbar()
  const { emailTemplates } = useTenantInfo()
  const { updateReservation } = useEscapeRooms()

  const activeEmailTemplate = getActiveEmailTemplateByRoomId(emailTemplates, roomId, emailType)

  const [files, setFiles] = useState([])
  const [response, setResponse] = useState(null)
  const [emailTemplateId, setEmailTemplateId] = useState(activeEmailTemplate?.id)
  const [emailDynamicValues, setEmailDynamicValues] = useState(
    activeEmailTemplate
      ? Object.keys(activeEmailTemplate.dynamicValues)?.reduce((acc, cur) => ({ ...acc, [cur.slice(1, -1)]: '' }), {})
      : {}
  )

  const { getInputProps, open, getRootProps } = useDropzone({
    accept: 'image/*',
    maxFiles,
    maxSize: 30000000, // 30MB
    multiple: true,
    onDropRejected: (fileRejections) => {
      fileRejections.map((file) => enqueueSnackbar(file.errors[0].message, { variant: 'error' }))
    },
    onDropAccepted: async (acceptedFiles) => {
      if (acceptedFiles.length + files.length > maxFiles) {
        enqueueSnackbar(`Max ${maxFiles} files`, { variant: 'error' })
        return
      }

      const resizedFiles = await Promise.all(
        acceptedFiles.map((file) => {
          Object.defineProperty(file, 'name', {
            writable: true,
            value: tenantId ? `${tenantId}/${uuid()}.${format}` : `${uuid()}.${format}`,
          })

          return resizeImage(file, 1920, 1080)
        })
      )

      setFiles((initialFiles) => [
        ...(keepAlreadyUploadedFiles ? initialFiles : []),
        ...resizedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        }),
      ])
    },
  })

  const thumbs = files.map((file) => (
    <File key={file.name} imgSrc={file.preview} removeFile={() => removeFile(file.name)} />
  ))

  let formData = new FormData()

  useEffect(
    () => () => {
      if (instantRevoke) {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file) => URL.revokeObjectURL(file.preview))
      }
    },
    [files, instantRevoke]
  )

  const removeFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName))
  }

  const handleSendEmail = async () => {
    if (!emailTemplateId) {
      enqueueSnackbar(t('YouNeedToSelectAnEmailTemplate'), { variant: 'error' })
      return
    }

    files.forEach((file) => formData.append('file', file))
    formData.append('values', JSON.stringify(emailDynamicValues))
    const isEmailPostGame =
      emailTemplates.find((template) => template.id === emailTemplateId).assignments?.[0].emailType ===
      EMAIL_TYPES['POST_GAME'].key

    try {
      const { data } = await axios.post(
        `${url}/${emailTemplateId}?${queryParamAsArray('to', recipients)}${
          reservation.key.reservationId ? `&reservationId=${reservation.key.reservationId}` : ''
        }${roomId ? `&roomId=${roomId}` : ''}${languageTag ? `&languageTag=${languageTag}` : ''}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      if (isEmailPostGame && !reservation.metadata.imagesEmailSent) {
        await updateReservation(
          {
            ...reservation,
            metadata: {
              imagesEmailSent: true,
            },
          },
          reservation.key,
          undefined,
          false
        )
      }

      await callbackFn()

      enqueueSnackbar(t('EmailSentSuccessfully'), { variant: 'success' })
      setResponse(data)
      return data
    } catch (error) {
      setError(error)
    }
  }

  return {
    handleSendEmail,
    open,
    getInputProps,
    getRootProps,
    files,
    activeEmailTemplate,
    previews: thumbs,
    response,
    removeFile,
    setFiles,
    emailTemplateId,
    setEmailTemplateId,
    emailDynamicValues,
    setEmailDynamicValues,
  }
}

export default useSendEmail
