import { AvatarGroup } from '@mui/material'
import { CustomChip, ProfileAvatar } from 'components'
import { API_URL } from 'config'
import { useEscapeRooms, useTenantInfo } from 'core'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Date, Link, String, Price, Status, AdditionalField, SubscriptionStatus, EscapeRooms } from './components'
import {
  PaymentStatus,
  Status as ReservationStatus,
} from 'views/Calendar/components/Reservations/components/Reservation/components'

export const FieldType = ({ column, value, row, isAdditionalField = false, setIsUpdated, additionalFieldValue, t }) => {
  const { type, additionalFieldType, component, additionalFieldComponent } = column
  const { escapeRooms } = useEscapeRooms()
  const { tenants } = useTenantInfo()

  if (isAdditionalField && !!additionalFieldComponent) {
    return additionalFieldComponent(value, row)
  }

  if (!!component) {
    return component(value, row)
  }

  switch (isAdditionalField ? additionalFieldType : type) {
    case 'avatars':
      return (
        value !== undefined && (
          <AvatarGroup max={4}>
            {value.map((user) => (
              <ProfileAvatar
                key={user?.id}
                avatar={user?.avatar && `${API_URL}/media/${user?.avatar}`}
                firstName={user?.firstName}
                lastName={user?.lastName}
                cursor="pointer"
                showTooltip={true}
                sx={{ cursor: 'default', width: 32, height: 32 }}
              />
            ))}
          </AvatarGroup>
        )
      )
    case 'date':
      return (
        <String>
          <Date>{value}</Date>
        </String>
      )
    case 'date-and-hours':
      return (
        <String>
          <Date isFullDate>{value}</Date>
        </String>
      )
    case 'price':
      return <Price>{value}</Price>
    case 'phone':
      return <Link isPhone>{value}</Link>
    case 'status':
      return <Status>{value}</Status>
    case 'subscription-status':
      return <SubscriptionStatus>{value}</SubscriptionStatus>
    case 'reservation-status':
      return (
        <ReservationStatus
          isCalendarView={false}
          reservation={row}
          setIsUpdated={setIsUpdated}
          reservationAssignments={undefined}
        />
      )
    case 'composition':
      return (
        <String>
          {value} {additionalFieldValue}
        </String>
      )
    case 'team-size':
      return <String>{t('reservations:XPeople', { x: value })}</String>
    case 'escape-room':
      const escapeRoom = escapeRooms?.find((escapeRoom) => escapeRoom.id === value)
      return (
        <String>
          {escapeRoom?.name && (
            <CustomChip
              size="small"
              variant="outlined"
              color={escapeRoom.additionalInfo.roomColor}
              label={escapeRoom?.name}
            />
          )}
        </String>
      )
    case 'escape-rooms':
      return <EscapeRooms passedEscapeRooms={value} />
    case 'tenantId':
      return <Link to={row[column.href]}>{tenants?.find((tenant) => tenant.id === value)?.name}</Link>
    case 'payment-status':
      return <PaymentStatus reservation={row} isCalendarView={false} setIsUpdated={setIsUpdated} />
    case 'string':
    default:
      return <String>{value}</String>
  }
}

const DisplayValue = ({ column, additionalFieldValue, row, setIsUpdated, children: value }) => {
  const { t } = useTranslation()

  if (column.isAdditionalField) {
    return <AdditionalField column={column} value={value} row={row} additionalFieldValue={additionalFieldValue} t={t} />
  } else {
    return (
      <FieldType
        column={column}
        value={value}
        row={row}
        setIsUpdated={setIsUpdated}
        additionalFieldValue={additionalFieldValue}
        t={t}
      />
    )
  }
}

export default memo(DisplayValue)
