import { Alert, Box, ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material'
import { ButtonTriggeredDialog } from 'components'
import { useTranslation } from 'react-i18next'
import { useTenantInfo } from 'core'
import { useEffect, useMemo, useState } from 'react'
import { filterEmailTemplatesByRoomId } from 'core/contexts/TenantInfoContext/utils'
import { useSendEmail } from 'utils/hooks'
import { isValidEmail, urlToFile } from 'helpers'
import useSWR from 'swr'
import { API_URL, EMAIL_TYPES, ACTION_HISTORY_TYPES } from 'config'
import { v4 as uuid } from 'uuid'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import EmailIcon from '@mui/icons-material/Email'
import { useActionHistory } from 'utils/hooks/useActionHistory'
import {
  AttachFiles,
  DynamicFieldsTextFields,
  EmailRecipients,
  EmailTemplateSelect,
  SentEmailsHistory,
} from './components'

export default function SendEmail({ reservation, isIcon = false, callbackFn = async () => {} }) {
  const { emailTemplates } = useTenantInfo()
  const { t } = useTranslation('reservations')

  const [openDialog, setOpenDialog] = useState(false)

  const {
    actionHistory,
    hasNext,
    setAsc,
    setCountClicks,
    setFrom,
    setPageSize,
    startNextOrPreviousPageFrom,
    countClicks,
    asc,
    from,
    pageSize,
    isLoading,
  } = useActionHistory({
    pause: !openDialog,
    type: ACTION_HISTORY_TYPES.EMAIL_SENT.key,
    reservationId: reservation.key.reservationId,
    roomId: reservation.key.roomId,
  })

  const { data: waiverData, isLoading: isWaiverEmailsLoading } = useSWR(
    openDialog &&
      `${API_URL}/waiver/${reservation.tenantId}/${reservation.key.roomId}/${reservation.key.reservationId}`,
    {
      shouldRetryOnError: false,
    }
  )

  const { data: photosData, isLoading: isPhotosLoading, mutate: mutatePhotos } = useSWR(
    openDialog && `${API_URL}/reservation/${reservation.key.roomId}/${reservation.key.reservationId}/images`,
    {
      shouldRetryOnError: false,
      fallbackData: [],
    }
  )

  const waiverEmails = useMemo(
    () =>
      waiverData?.customers.filter((customer) => isValidEmail(customer.email)).map((customer) => customer.email) || [],
    [waiverData]
  )

  const initialEmails = [
    ...new Set(
      reservation.player.email && isValidEmail(reservation.player.email)
        ? [reservation.player.email, ...waiverEmails]
        : [...waiverEmails]
    ),
  ]

  const [emails, setEmails] = useState(() => initialEmails)
  const [processedUrls, setProcessedUrls] = useState([])

  const {
    emailDynamicValues,
    setEmailDynamicValues,
    emailTemplateId,
    setEmailTemplateId,
    open,
    getInputProps,
    setFiles,
    previews,
    handleSendEmail,
  } = useSendEmail({
    callbackFn,
    roomId: reservation.key.roomId,
    languageTag: reservation.language,
    tenantId: reservation.tenantId,
    reservation,
    recipients: !!emails.length ? emails.filter((email) => isValidEmail(email)) : initialEmails,
    keepAlreadyUploadedFiles: true,
  })

  const isEmailPostGame =
    emailTemplates?.find((template) => template?.id === emailTemplateId)?.assignments?.[0]?.emailType ===
    EMAIL_TYPES['POST_GAME'].key

  const filteredEmailTemplates = filterEmailTemplatesByRoomId(emailTemplates, reservation.key.roomId)

  useEffect(() => {
    if (!isWaiverEmailsLoading && waiverEmails.length > 0) {
      setEmails((emails) => [...new Set([...emails, ...waiverEmails])])
    }
  }, [isWaiverEmailsLoading, setEmails, waiverEmails])

  useEffect(() => {
    if (photosData.length && !isPhotosLoading) {
      async function handleSetInitialFiles(photoUrls) {
        // Filter out URLs that have already been processed
        const newUrls = photoUrls.filter((url) => !processedUrls.includes(url))

        const newFiles = await Promise.all(
          newUrls.map(async (url) => {
            const fileName = uuid()
            return await urlToFile(url, `photo-${fileName}.jpg`)
          })
        )

        // Only update files and processedUrls with the new ones
        if (newFiles.length) {
          setFiles((prevFiles) => [
            ...prevFiles,
            ...newFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ])

          setProcessedUrls((prevUrls) => [...prevUrls, ...newUrls])
        }
      }

      handleSetInitialFiles(photosData.map((item) => item.url))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhotosLoading, photosData])

  return (
    <ButtonTriggeredDialog
      open={openDialog}
      setOpen={setOpenDialog}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      buttonComponent={MenuItem}
      actionButtonProps={{ disabled: !emailTemplateId || !emails.length || isPhotosLoading || isWaiverEmailsLoading }}
      actionText={t('photos:SendEmail')}
      dialogTitle={
        <Box display="flex" alignItems="center" gap={1}>
          <span>{t('photos:SendEmail')}</span>{' '}
          <SentEmailsHistory
            history={actionHistory}
            hasNext={hasNext}
            setAsc={setAsc}
            setCountClicks={setCountClicks}
            setFrom={setFrom}
            setPageSize={setPageSize}
            startNextOrPreviousPageFrom={startNextOrPreviousPageFrom}
            countClicks={countClicks}
            asc={asc}
            from={from}
            pageSize={pageSize}
            isLoading={isLoading}
          />
        </Box>
      }
      handleClick={handleSendEmail}
      icon={isIcon && EmailIcon}
      buttonText={
        isIcon ? (
          t('photos:SendEmail')
        ) : (
          <>
            <ListItemIcon>
              <EmailOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('photos:SendEmail')}</ListItemText>
          </>
        )
      }
    >
      <Stack gap={1}>
        {isEmailPostGame && reservation.metadata.imagesEmailSent && (
          <Alert severity="warning">{t('PostGameEmailAlreadySent')}</Alert>
        )}
        <EmailTemplateSelect
          emailTemplateId={emailTemplateId}
          filteredEmailTemplates={filteredEmailTemplates}
          setEmailTemplateId={setEmailTemplateId}
        />
        <DynamicFieldsTextFields
          emailTemplateId={emailTemplateId}
          setEmailDynamicValues={setEmailDynamicValues}
          emailDynamicValues={emailDynamicValues}
          filteredEmailTemplates={filteredEmailTemplates}
        />
        <EmailRecipients emails={emails} setEmails={setEmails} />
        <AttachFiles
          open={open}
          getInputProps={getInputProps}
          previews={previews}
          reservation={reservation}
          mutatePhotos={mutatePhotos}
        />
      </Stack>
    </ButtonTriggeredDialog>
  )
}
