import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
  Typography,
} from '@mui/material'
import { RESERVATION_STATUSES, API_URL, PAYMENT_STATUSES } from 'config'
import { useCalendar, useEscapeRooms, useTenantInfo } from 'core'
import { deepEqual, isValidEmail, urlToFile } from 'helpers'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getReservationAssignmentsForReservation, transformOrderEntriesToCartObject } from 'utils'
import { useError, usePricing, useSendEmail } from 'utils/hooks'
import { Assignees, Marketing, Pricing } from './components'
import { Close, WarningAmber } from '@mui/icons-material'
import useSWR from 'swr'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { AddRemoveProductsFromCart } from 'components'
import axios from 'axios'
import { getEntriesToAdd, getEntriesToRemove } from 'components/AddRemoveProductsFromCart/utils'
import { v4 as uuid } from 'uuid'
import { PAYMENT_METHODS } from 'config'
import { LoadingButton } from '@mui/lab'

export const MAX_FILES = 10

const PlayedReservationDialog = ({
  order,
  isLoading,
  reservation,
  setIsLoading,
  handleClose,
  open: openPlayedDialog,
  handleCloseMainMenu,
  isCalendarView = true,
  reservationAssignments: passedReservationAssignments,
  setIsUpdated = () => {},
}) => {
  const { t } = useTranslation('reservations')
  const { setError } = useError()
  const { refetchProducts } = useTenantInfo()

  const tenantId = reservation.tenantId
  const roomId = reservation.key.roomId
  const reservationId = reservation.key.reservationId

  const { data: orderData, isLoading: isOrdersLoading } = useSWR(
    openPlayedDialog && !order && `${API_URL}/${tenantId}/order-history/${roomId}/${reservationId}`,
    { shouldRetryOnError: false, fallbackData: [] }
  )

  const { data: waiverData, isLoading: isWaiverEmailsLoading } = useSWR(
    openPlayedDialog && `${API_URL}/waiver/${tenantId}/${roomId}/${reservationId}`,
    { shouldRetryOnError: false }
  )

  const { data: photosData, isLoading: isPhotosLoading, mutate: mutatePhotos } = useSWR(
    openPlayedDialog && `${API_URL}/reservation/${roomId}/${reservationId}/images`,
    {
      shouldRetryOnError: false,
      fallbackData: [],
    }
  )

  const waiverEmails = useMemo(
    () =>
      waiverData?.customers.filter((customer) => isValidEmail(customer.email)).map((customer) => customer.email) || [],
    [waiverData]
  )

  const initialEmails = [
    ...new Set(
      reservation.player.email && isValidEmail(reservation.player.email)
        ? [reservation.player.email, ...waiverEmails]
        : [...waiverEmails]
    ),
  ]

  const [emails, setEmails] = useState(() => initialEmails)
  const [processedUrls, setProcessedUrls] = useState([])

  const {
    emailDynamicValues,
    setEmailDynamicValues,
    emailTemplateId,
    setEmailTemplateId,
    open,
    getInputProps,
    files,
    setFiles,
    previews,
    handleSendEmail,
  } = useSendEmail({
    roomId,
    languageTag: reservation.language,
    tenantId,
    reservation,
    recipients: !!emails.length ? emails.filter((email) => isValidEmail(email)) : initialEmails,
    keepAlreadyUploadedFiles: true,
  })

  const { changeReservationStatus, updateReservation } = useEscapeRooms()
  const { loadCalendar } = useCalendar()
  const { flattenedProducts, activeTenantUsers } = useTenantInfo()
  const orderedProducts = transformOrderEntriesToCartObject(order?.orderEntries ?? [], flattenedProducts)

  const [selectedPlayers, setSelectedPlayers] = useState(reservation.playerCount)
  const [sendPostGameEmail, setSendPostGameEmail] = useState(isValidEmail(reservation.player.email))
  const [code, setCode] = useState(reservation.discountCode === null ? '' : reservation.discountCode)
  const [disableActionButton, setDisableActionButton] = useState(true)
  const [isSaveInProgress, setIsSaveInProgress] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [total, setTotal] = useState(0)
  const [cart, setCart] = useState(orderedProducts)
  const [paymentMethod, setPaymentMethod] = useState(
    reservation.paymentStatus === PAYMENT_STATUSES.PAID_ONLINE.key
      ? PAYMENT_METHODS.STRIPE.key
      : PAYMENT_METHODS.NONE.key
  )
  const [initialCart, setInitialCart] = useState(orderedProducts)
  const [reservationAssignments, setReservationAssignments] = useState(passedReservationAssignments)

  const { productsTotal } = usePricing({ cart })

  const handleChangeStep = (newStep) => {
    setActiveStep(newStep)
  }

  const handleNextStep = () => {
    setActiveStep((step) => step + 1)
  }

  const handlePreviousStep = () => {
    setActiveStep((step) => (step === 0 ? 0 : step - 1))
  }

  const allConditionsMet = !sendPostGameEmail || (sendPostGameEmail && !!emailTemplateId && !!files.length)

  useEffect(() => {
    if (!order && !isOrdersLoading && !!orderData?.orderEntries?.length) {
      setCart(transformOrderEntriesToCartObject(orderData?.orderEntries ?? [], flattenedProducts))
      setInitialCart(transformOrderEntriesToCartObject(orderData?.orderEntries ?? [], flattenedProducts))
    }
  }, [order, orderData, isOrdersLoading, flattenedProducts])

  useEffect(() => {
    if (!isWaiverEmailsLoading && waiverEmails.length > 0) {
      setEmails((emails) => [...new Set([...emails, ...waiverEmails])])
      setSendPostGameEmail(true)
    }
  }, [isWaiverEmailsLoading, setEmails, setSendPostGameEmail, waiverEmails])

  useEffect(() => {
    if (photosData.length && !isPhotosLoading) {
      async function handleSetInitialFiles(photoUrls) {
        // Filter out URLs that have already been processed
        const newUrls = photoUrls.filter((url) => !processedUrls.includes(url))

        const newFiles = await Promise.all(
          newUrls.map(async (url) => {
            const fileName = uuid()
            return await urlToFile(url, `photo-${fileName}.jpg`)
          })
        )

        // Only update files and processedUrls with the new ones
        if (newFiles.length) {
          setFiles((prevFiles) => [
            ...prevFiles,
            ...newFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            ),
          ])

          setProcessedUrls((prevUrls) => [...prevUrls, ...newUrls])
        }
      }

      handleSetInitialFiles(photosData.map((item) => item.url))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhotosLoading, photosData])

  const generateSteps = () => {
    const gameMastersStep = {
      id: 'game-masters',
      title: t('GameMasterAssignment'),
      component: (
        <Assignees
          reservationId={reservationId}
          roomId={roomId}
          reservationAssignments={reservationAssignments}
          isCalendarView={isCalendarView}
          setDisableActionButton={setDisableActionButton}
          setReservationAssignments={setReservationAssignments}
        />
      ),
    }
    const marketingStep = {
      id: 'marketing',
      title: t('Marketing'),
      component: (
        <Marketing
          open={open}
          getInputProps={getInputProps}
          files={files}
          emails={emails}
          setEmails={setEmails}
          setSendPostGameEmail={setSendPostGameEmail}
          sendPostGameEmail={sendPostGameEmail}
          previews={previews}
          reservation={reservation}
          mutatePhotos={mutatePhotos}
          emailTemplateId={emailTemplateId}
          setEmailTemplateId={setEmailTemplateId}
          emailDynamicValues={emailDynamicValues}
          setEmailDynamicValues={setEmailDynamicValues}
        />
      ),
    }
    const productsStep = {
      id: 'products',
      title: t('AdditionalPurchases'),
      component: <AddRemoveProductsFromCart roomId={roomId} cart={cart} setCart={setCart} />,
    }
    const summaryStep = {
      id: 'summary',
      title: t('Summary'),
      component: (
        <Pricing
          code={code}
          cart={cart}
          total={total}
          setCode={setCode}
          setTotal={setTotal}
          reservation={reservation}
          setIsLoading={setIsLoading}
          selectedPlayers={selectedPlayers}
          paymentMethod={paymentMethod}
          openPlayedDialog={openPlayedDialog}
          setPaymentMethod={setPaymentMethod}
          setSelectedPlayers={setSelectedPlayers}
        />
      ),
    }

    const steps = [gameMastersStep, marketingStep, summaryStep]

    if (flattenedProducts.some((product) => product.relatedRoomIds.includes(roomId))) {
      steps.splice(2, 0, productsStep)
    }

    return steps
  }

  const steps = generateSteps()

  const updateOrder = async () => {
    try {
      const { data: updatedOrderData } = await axios.put(`${API_URL}/${tenantId}/product/reservation`, {
        key: {
          roomId,
          reservationId: reservation.key.reservationId,
        },
        entriesToAdd: getEntriesToAdd(cart, initialCart),
        entriesToRemove: getEntriesToRemove(cart, initialCart),
      })
      const newCart = transformOrderEntriesToCartObject(updatedOrderData.orderEntries, flattenedProducts)

      if (!deepEqual(newCart, initialCart)) {
        setInitialCart(newCart)
      }
      refetchProducts()
    } catch (error) {
      setError(error)
    }
  }

  const isStepError = (step) => {
    if (activeStep !== 0 && step === 0) {
      return !!!reservationAssignments.length
    }
    if (activeStep !== 1 && step === 1) {
      return (sendPostGameEmail && !emailTemplateId) || (sendPostGameEmail && !files.length)
    }
  }

  const getOptionalMessage = (step) => {
    if (step === 0) {
      if (isStepError(step)) {
        return t('NoGameMastersAddedDisclaimer')
      } else {
        return reservationAssignments
          ?.map((_user) => {
            const user = activeTenantUsers.find((activeTenantUser) => activeTenantUser.id === _user.userId)
            return `${user.firstName} ${user.lastName}`
          })
          .join(', ')
      }
    }

    if (step === 1) {
      return sendPostGameEmail && !!emailTemplateId ? t('MarketingWillProceed') : t('MarketingWillNotProceed')
    }

    if (step === 2 && steps.length > 3) {
      return Object.keys(cart).filter((productId) => cart[productId] > 0).length
        ? Object.keys(cart)
            .filter((productId) => cart[productId] > 0)
            .map(
              (productId) =>
                `${t('products:XpcsOfProduct', {
                  count: cart[productId],
                  productName: flattenedProducts.find((product) => product.id === productId).name,
                })}`
            )
            .join(', ')
        : t('products:NoAddedProducts')
    }

    return ''
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.stopPropagation()
    }
  }

  return (
    <Dialog open={openPlayedDialog} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {t('ConfirmPlayedReservationForX', {
          x: reservation?.teamName || reservation?.player?.name || reservation?.player?.email,
        })}
        <IconButton
          onClick={() => {
            handleClose()
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent onKeyDown={handleKeyDown}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.title} onClick={() => handleChangeStep(index)}>
              <StepLabel
                sx={{ cursor: index === activeStep ? 'default' : 'pointer !important', lineHeight: 1 }}
                optional={<Typography variant="caption">{getOptionalMessage(index)}</Typography>}
                error={isStepError(index)}
              >
                {step.title}
              </StepLabel>
              <StepContent>{step.component}</StepContent>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleChangeStep(steps.length - 1)
          }}
        >
          {t('reservations:SkipToLast')}
        </Button>
        <Button
          disabled={activeStep === 0}
          startIcon={<ArrowRightAltIcon sx={{ transform: 'rotate(-90deg)' }} />}
          onClick={handlePreviousStep}
        >
          {t('translation:Back')}
        </Button>
        <LoadingButton
          color={allConditionsMet ? 'primary' : activeStep !== steps.length - 1 ? 'primary' : 'warning'}
          variant={activeStep !== steps.length - 1 ? 'text' : 'contained'}
          loading={isSaveInProgress}
          disabled={
            activeStep !== steps.length - 1
              ? false
              : isLoading ||
                disableActionButton ||
                !!!getReservationAssignmentsForReservation({
                  reservationId,
                  reservationAssignments,
                }).length
          }
          endIcon={
            activeStep !== steps.length - 1 ? (
              <ArrowRightAltIcon sx={{ transform: 'rotate(90deg)' }} />
            ) : !allConditionsMet ? (
              <WarningAmber />
            ) : null
          }
          onClick={async () => {
            if (activeStep !== steps.length - 1) {
              handleNextStep()
            } else {
              setIsSaveInProgress(true)
              if (!!getEntriesToAdd(cart, initialCart).length || !!getEntriesToRemove(cart, initialCart).length) {
                await updateOrder()
              }
              if (sendPostGameEmail) {
                await updateReservation(
                  {
                    ...reservation,
                    metadata: {
                      imagesEmailSent: true,
                    },
                  },
                  reservation.key,
                  undefined,
                  false
                )
              }

              const data = await changeReservationStatus({
                reservationId: reservation.key.reservationId,
                roomId: reservation.key.roomId,
                status: RESERVATION_STATUSES.PLAYED.key,
                playerCount: reservation.playerCount !== selectedPlayers ? selectedPlayers : null,
                discountCode: code,
                paymentMethod,
                // we remove the products as the backend needs to receive the actual final price from the game
                // so they can split properly the finances
                amount: total - productsTotal,
                ...(reservation.paymentStatus === PAYMENT_STATUSES['PAID_ONLINE'].key
                  ? {}
                  : { paymentStatus: PAYMENT_STATUSES['PAID_AT_VENUE'].key }),
              })

              if (!!data) {
                handleClose()
                handleCloseMainMenu()
                sendPostGameEmail && !!emailTemplateId && handleSendEmail()
                isCalendarView && (await loadCalendar())
                setIsUpdated(data)
              }
              setIsSaveInProgress(false)
            }
          }}
        >
          {activeStep !== steps.length - 1 ? t('translation:Next') : t('translation:Confirm')}
        </LoadingButton>
      </DialogActions>
      {sendPostGameEmail && !emailTemplateId && (
        <FormHelperText sx={{ mt: 0, textAlign: 'right', mb: 0.5, mr: 2, color: 'warning.main' }}>
          {t('MissingEmailTemplate')}
        </FormHelperText>
      )}
      {sendPostGameEmail && emailTemplateId && !files.length && (
        <FormHelperText sx={{ mt: 0, textAlign: 'right', mb: 0.5, mr: 2, color: 'warning.main' }}>
          {!files.length && t('MissingTeamPhoto')}
        </FormHelperText>
      )}
    </Dialog>
  )
}

export default PlayedReservationDialog
