import axios from 'axios'
import { API_URL, TRANSACTION_TYPES, FINANCE_TYPES } from 'config'
import useError from './useError'
import { getEntriesToRemove } from 'components/AddRemoveProductsFromCart/utils'
import { PAYMENT_METHODS } from 'config'

export default function useFinanceMethods() {
  const { setError } = useError()

  const refundFinance = async (income, amount) => {
    try {
      if (income.paymentIntentId) {
        await axios.post(`${API_URL}/stripe-payment/intent/refund`, { paymentIntentId: income.paymentIntentId })
      } else {
        await axios.post(`${API_URL}/finances`, {
          tenantId: income.tenantId,
          roomId: income.roomId,
          financeType: FINANCE_TYPES['EXPENSE'].key,
          transactionType: TRANSACTION_TYPES['REFUND_AT_VENUE'].key,
          amount,
          reservationId: income.reservationId,
          description: `Refund ${income.reservationId} ${income.roomId}`,
          executionDate: Date.now(),
        })
      }
    } catch (error) {
      setError(error)
    }
  }

  const refundProducts = async (income, cart, initialCart) => {
    try {
      if (income.reservationId) {
        await axios.put(`${API_URL}/${income.tenantId}/product/reservation`, {
          key: {
            roomId: income.roomId,
            reservationId: income.reservationId,
          },
          entriesToAdd: [],
          entriesToRemove: getEntriesToRemove(cart, initialCart),
        })
      } else {
        await axios.post(`${API_URL}/${income.tenantId}/product/refund`, {
          orderHistoryId: income.orderHistoryId,
          entriesToRefund: getEntriesToRemove(cart, initialCart),
        })
      }
    } catch (error) {
      setError(error)
    }
  }

  const deleteFinance = async (incomeId) => {
    try {
      await axios.delete(`${API_URL}/finances/${incomeId}`)
    } catch (error) {
      setError(error)
    }
  }

  const createFinance = async (finance, url = `${API_URL}/finances`) => {
    try {
      await axios.post(url, {
        amount: finance.amount,
        description: finance.description,
        executionDate: finance.executionDate,
        transactionType: finance.transactionType ?? TRANSACTION_TYPES.NONE.key,
        financeType: finance.financeType ?? FINANCE_TYPES.INCOME.key,
        roomId: finance.roomId,
        paymentMethod: finance.paymentMethod ?? PAYMENT_METHODS.NONE.key,
        tenantId: finance.tenantId,
        externalFee: finance.externalFee,
        reservationId: finance.reservationId,
        recurringFinanceId: finance.recurringFinanceId,
        userId: finance.userId,
        orderHistoryId: finance.orderHistoryId,
      })
    } catch (error) {
      setError(error)
    }
  }

  const updateFinance = async (finance, url = `${API_URL}/finances`) => {
    try {
      await axios.put(url, {
        id: finance.id,
        amount: finance.amount,
        description: finance.description,
        executionDate: finance.executionDate,
        transactionType: finance.transactionType ?? TRANSACTION_TYPES.NONE.key,
        financeType: finance.financeType ?? FINANCE_TYPES.INCOME.key,
        paymentMethod: finance.paymentMethod ?? PAYMENT_METHODS.NONE.key,
        roomId: finance.roomId,
        tenantId: finance.tenantId,
        externalFee: finance.externalFee,
        reservationId: finance.reservationId,
        recurringFinanceId: finance.recurringFinanceId,
        userId: finance.userId,
        orderHistoryId: finance.orderHistoryId,
      })
    } catch (error) {
      setError(error)
    }
  }

  return {
    createFinance,
    updateFinance,
    refundFinance,
    refundProducts,
    deleteFinance,
  }
}
