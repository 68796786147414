export const filterEmailTemplatesByRoomId = (emailTemplates, roomId) => {
  return emailTemplates.filter(
    (template) =>
      template.assignments.map((assignment) => assignment.roomId).includes(roomId) ||
      template?.assignments?.[0]?.roomId === null
  )
}

export const getActiveEmailTemplateByRoomId = (emailTemplates, roomId, emailType) => {
  return (
    emailTemplates.find((template) =>
      template.assignments.some(
        (assignment) => assignment.roomId === roomId && assignment.active && assignment.emailType === emailType
      )
    ) ??
    emailTemplates.find(
      (template) => template.assignments?.[0].roomId === null && template.assignments?.[0].emailType === emailType
    )
  )
}
