import { DisplayCurrency } from 'components'
import { calculatePercentage } from 'utils/calculate'
import PricingItem from '../PricingItem'
import { Alert, Box, CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useTenantInfo } from 'core'
import {
  GIFTCARD_TYPES,
  TRANSACTION_TYPES,
  FINANCE_TYPES,
  RESERVATION_STATUSES,
  INCOME_TRANSACTION_TYPES,
} from 'config'
import { getDescription } from './utils'

export default function Summary({
  cart,
  subtotal,
  giftCard,
  escapeRoom,
  balanceDue,
  reservation,
  validProducts,
  selectedPlayers,
  selectedGameMode,
  existingFinances,
  isValidateLoading,
}) {
  const { t } = useTranslation('reservations')
  const { flattenedProducts } = useTenantInfo()

  const isReservationPlayed = reservation.status === RESERVATION_STATUSES.PLAYED.key

  return (
    <>
      {!isReservationPlayed &&
        balanceDue > 0 &&
        !!existingFinances.find(
          (finance) =>
            finance.transactionType === INCOME_TRANSACTION_TYPES['FULL_PAYMENT'].key &&
            !existingFinances.some((f) => f.transactionType === TRANSACTION_TYPES['REFUND_AT_VENUE'].key) &&
            !finance.paymentIntentId
        ) && <Alert severity="warning">{t('finances:ThereIsAFinalPaymentAlready')}</Alert>}
      {!isValidateLoading ? (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            fontSize: '12pt',
            marginTop: '9pt',
          }}
        >
          <PricingItem label={t('GameForX', { x: selectedPlayers })}>
            <DisplayCurrency
              number={
                escapeRoom.pricingList.find(({ numberOfPlayers }) => numberOfPlayers === selectedPlayers)?.price ?? 0
              }
            />
          </PricingItem>
          {!!selectedGameMode?.uid && (
            <PricingItem
              label={`${t('GameMode')}: ${
                escapeRoom.gameplayInfo.gameModes.find((gameMode) => gameMode.uid === selectedGameMode?.uid)
                  ?.localizedName?.[escapeRoom.gameplayInfo.supportedLanguages[0]]
              }`}
            >
              <DisplayCurrency number={selectedGameMode?.priceVariation ?? 0} />
            </PricingItem>
          )}
          {validProducts.map((productId) => {
            const product = flattenedProducts.find((product) => product.id === productId)
            const selectedQuantity = cart[productId]

            return (
              <PricingItem
                key={productId}
                label={`${t('products:XpcsOfProduct', {
                  count: selectedQuantity,
                  productName: product.name,
                })}`}
              >
                ({selectedQuantity}x<DisplayCurrency number={product.price} />){' '}
                <DisplayCurrency number={product.price * selectedQuantity} />
              </PricingItem>
            )
          })}
          {(existingFinances.length || giftCard) && (
            <PricingItem label={t('Subtotal')} bold>
              <DisplayCurrency number={subtotal} />
            </PricingItem>
          )}

          {giftCard && (
            <PricingItem label={t('Discount')}>
              {giftCard ? (
                giftCard?.type === GIFTCARD_TYPES.PERCENTAGE.key ? (
                  <span>
                    -{giftCard?.amount}% (
                    <DisplayCurrency
                      number={
                        -calculatePercentage(
                          giftCard.amount,
                          escapeRoom.pricingList.find(({ numberOfPlayers }) => numberOfPlayers === selectedPlayers)
                            ?.price ?? 0 + selectedGameMode.priceVariation
                        )
                      }
                    />
                    )
                  </span>
                ) : (
                  <>
                    -<DisplayCurrency number={giftCard.amount} />
                  </>
                )
              ) : (
                <DisplayCurrency number={0} />
              )}
            </PricingItem>
          )}

          {existingFinances
            .filter((finance) => finance.transactionType !== TRANSACTION_TYPES.PRODUCT.key)
            .map((finance) => {
              const label = getDescription(finance, t)

              return (
                <PricingItem key={finance.id} label={label}>
                  {finance.transactionType === TRANSACTION_TYPES.NONE.key ? (
                    <>
                      <Box component="span" sx={{ textDecoration: 'line-through' }}>
                        <DisplayCurrency
                          number={
                            finance.financeType === FINANCE_TYPES['INCOME'].key ? -finance.amount : finance.amount
                          }
                        />
                      </Box>
                      <DisplayCurrency number={0} />
                    </>
                  ) : (
                    <DisplayCurrency
                      number={finance.financeType === FINANCE_TYPES['INCOME'].key ? -finance.amount : finance.amount}
                    />
                  )}
                </PricingItem>
              )
            })}

          <PricingItem label={t('Total')} bold>
            <DisplayCurrency number={balanceDue} />
          </PricingItem>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
          <CircularProgress />
        </Box>
      )}
    </>
  )
}
