import { alpha } from 'utils'

/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiDialog: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(2px) brightness(0.25)',
      },
      paper: {
        borderRadius: 16,
        background: theme.palette.mode === 'dark' ? theme.palette.grey[800] : 'white',
        boxShadow: `-40px 40px 80px -8px ${alpha('#000000', 0.24)}`,
        margin: 16,
        maxHeight: 'calc(100% - 32px)',
        '@media only screen and (max-width: 600px)': {
          width: 'calc(100% - 32px)',
        },
      },
    },
  },
})
