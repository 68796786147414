import { Button, IconButton, Menu, Tooltip } from '@mui/material'
import { memo, useState, useEffect } from 'react'

const ButtonTriggeredMenu = ({
  actionText,
  buttonComponent: Component = Button,
  secondaryActionText,
  buttonText,
  tooltip,
  children,
  icon: Icon,
  additionalOnClose = () => {},
  disabled = false,
  menuProps,
  tooltipProps = {},
  iconProps = {},
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    additionalOnClose()
  }

  useEffect(() => {
    disabled && handleClose()
    // eslint-disable-next-line
  }, [disabled])

  return (
    <>
      {Icon ? (
        buttonText ? (
          <Tooltip title={tooltip ?? buttonText} disabled={disabled} {...tooltipProps}>
            <IconButton {...rest} onClick={handleClick}>
              <Icon {...iconProps} />
            </IconButton>
          </Tooltip>
        ) : (
          <IconButton {...rest} onClick={handleClick} disabled={disabled}>
            <Icon {...iconProps} />
          </IconButton>
        )
      ) : tooltip ? (
        <Tooltip title={tooltip} disabled={disabled} {...tooltipProps}>
          <Component onClick={handleClick} disabled={disabled} {...rest}>
            {buttonText}
          </Component>
        </Tooltip>
      ) : (
        <Component onClick={handleClick} disabled={disabled} {...rest}>
          {buttonText}
        </Component>
      )}

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} {...menuProps}>
        {children({ handleClose, open })}
      </Menu>
    </>
  )
}

export default memo(ButtonTriggeredMenu)
