import { Palette } from '@mui/icons-material'
import ButtonTriggeredMenu from 'components/ButtonTriggeredMenu'
import { ChromePicker } from 'react-color'

export default function ColorPickerButton({ label, value = '#000000', onChange, sx, ...rest }) {
  const color = value ?? '#000'
  return (
    <ButtonTriggeredMenu
      buttonText={label}
      startIcon={<Palette />}
      variant="contained"
      sx={(theme) => ({
        bgcolor: color,
        color: theme.palette?.getContrastText(color) ?? theme.palette.text.primary,
        ...sx,
      })}
      {...rest}
    >
      {() => <ChromePicker disableAlpha color={color} onChange={({ hex }) => onChange(hex)} />}
    </ButtonTriggeredMenu>
  )
}
