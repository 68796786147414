import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { RESERVATION_STATUSES } from 'config'
import { useCalendar, useEscapeRooms } from 'core'
import { useTranslation } from 'react-i18next'

const BlockedReservationDialog = ({
  open,
  handleCloseMainMenu,
  handleClose,
  reservation,
  isCalendarView = true,
  setIsUpdated = () => {},
}) => {
  const { t } = useTranslation('reservations')
  const { changeReservationStatus } = useEscapeRooms()
  const { loadCalendar } = useCalendar()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('SetStatusToBlocked')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('SetStatusToBlockedDisclaimer')}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClose()
          }}
        >
          {t('translation:Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            handleClose()
            handleCloseMainMenu()
            const newReservation = await changeReservationStatus({
              reservationId: reservation.key.reservationId,
              roomId: reservation.key.roomId,
              status: RESERVATION_STATUSES.BLOCKED.key,
            })
            isCalendarView && (await loadCalendar())
            setIsUpdated(newReservation)
          }}
        >
          {t('translation:Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BlockedReservationDialog
