import { Box, Checkbox, Divider, FormControlLabel, Stack, Typography } from '@mui/material'
import { InfoTooltip } from 'components'
import { useTranslation } from 'react-i18next'
import { useTenantInfo } from 'core'
import { filterEmailTemplatesByRoomId } from 'core/contexts/TenantInfoContext/utils'
import {
  AttachFiles,
  DynamicFieldsTextFields,
  EmailRecipients,
  EmailTemplateSelect,
} from '../../../../../SendEmail/components'

export default function Marketing({
  open,
  getInputProps,
  files,
  mutatePhotos,
  previews,
  emails,
  setEmails,
  setSendPostGameEmail,
  sendPostGameEmail,
  reservation,
  emailTemplateId,
  setEmailTemplateId,
  emailDynamicValues,
  setEmailDynamicValues,
}) {
  const { emailTemplates } = useTenantInfo()
  const { t } = useTranslation('reservations')

  const filteredEmailTemplates = filterEmailTemplatesByRoomId(emailTemplates, reservation.key.roomId)

  return (
    <>
      <Box display="flex" alignItems="center">
        <FormControlLabel
          sx={{ mx: 0 }}
          control={
            <Checkbox
              size="small"
              checked={sendPostGameEmail}
              onChange={(e) => setSendPostGameEmail(e.target.checked)}
            />
          }
          label={t('SendPostGameEmail')}
        />
        <InfoTooltip title={t('SendPostGameEmailTooltip')} />
      </Box>
      {sendPostGameEmail && (
        <>
          <Divider sx={{ mt: 1, mb: 1.5 }} />
          <Stack gap={1}>
            <EmailTemplateSelect
              emailTemplateId={emailTemplateId}
              filteredEmailTemplates={filteredEmailTemplates}
              setEmailTemplateId={setEmailTemplateId}
            />
            <DynamicFieldsTextFields
              emailTemplateId={emailTemplateId}
              setEmailDynamicValues={setEmailDynamicValues}
              emailDynamicValues={emailDynamicValues}
              filteredEmailTemplates={filteredEmailTemplates}
            />
            <EmailRecipients emails={emails} setEmails={setEmails} />
            <AttachFiles
              open={open}
              getInputProps={getInputProps}
              previews={previews}
              reservation={reservation}
              mutatePhotos={mutatePhotos}
            />
          </Stack>
        </>
      )}
      {!files.length && sendPostGameEmail && (
        <Typography variant="caption" sx={{ color: 'error.main' }}>
          {t('NoPhotoAddedDisclaimer')}
        </Typography>
      )}
    </>
  )
}
