import { Delete, EventNote, Person } from '@mui/icons-material'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import axios from 'axios'
import { ButtonTriggeredDialog, CustomBadge, ProfileAvatar, RichTextEditor } from 'components'
import { API_URL } from 'config'
import { useAuth, useDate, useTenantInfo } from 'core'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useError } from 'utils/hooks'

export default function Comment({ user, comment, fetchLatestComments }) {
  const { t } = useTranslation('reservations')
  const { setError } = useError()
  const { user: currentUser } = useAuth()
  const { activeTenant } = useTenantInfo()
  const commentQuillRef = useRef()
  const { localizedDateTimeInMillisRoomTz } = useDate()

  const handleDelete = async () => {
    try {
      await axios.delete(`${API_URL}/${activeTenant}/comment/${comment.id}`)
      fetchLatestComments()
    } catch (error) {
      setError(error)
    }
  }

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="flex-start" gap={1.5}>
      <Box pt="6px">
        <ProfileAvatar showTooltip={false} user={user} avatar={user?.avatar} />
      </Box>
      <Stack gap={1} sx={{ width: '100%' }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            <Typography fontWeight={600}>
              {user?.firstName} {user?.lastName}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {localizedDateTimeInMillisRoomTz(comment.timeCreated, { dateStyle: 'short', timeStyle: 'short' })}
            </Typography>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Tooltip placement="top" arrow title={t(comment.playerEmail ? 'LinkedToPlayer' : 'LinkedToReservation')}>
                {comment.playerEmail ? <Person fontSize="small" /> : <EventNote fontSize="small" />}
              </Tooltip>
              {comment.isPublic && (
                <CustomBadge color="warning.main" borderColor="warning.main">
                  <Tooltip title={t('PublicCommentDirectDescription')} placement="top" arrow>
                    {t('PublicComment')}
                  </Tooltip>
                </CustomBadge>
              )}
            </Box>
          </Box>
          {currentUser.username === comment.createdBy && (
            <ButtonTriggeredDialog
              size="small"
              iconProps={{ fontSize: 'small' }}
              icon={Delete}
              buttonText={t('DeleteComment')}
              handleClick={handleDelete}
              actionText={t('translation:Delete')}
            >
              {t('translation:AreYouSureToDeleteTheItem')}
            </ButtonTriggeredDialog>
          )}
        </Box>
        <RichTextEditor readOnly value={comment.comment} ref={commentQuillRef} className={'ql-text-only'} />
      </Stack>
    </Box>
  )
}
