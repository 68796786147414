import { Badge, Box, Tooltip, Typography, useTheme } from '@mui/material'
import { ProfileAvatar } from 'components'
import { API_URL } from 'config'
import { useChat, useDate, useTenantInfo } from 'core'
import { DateTime } from 'luxon'
import { memo } from 'react'
import { checkText } from 'utils/emojis'

const Message = ({ message, tiny }) => {
  const theme = useTheme()
  const { localizedDateTimeInMillisRoomTz } = useDate()
  const { userStatuses } = useChat()
  const { activeTenantUsers } = useTenantInfo()
  const user =
    activeTenantUsers !== null && activeTenantUsers.find((currentUser) => currentUser.username === message.username)

  const isUserOnline = () =>
    message.username in userStatuses ? (userStatuses[message.username] === 'ONLINE' ? true : false) : false

  const setBorderRadius = () => {
    const defaultBorderRadius = '4px'
    const roundedBorderRadius = '18px'
    if (message.isConsecutive) {
      if (message.consecutivePosition === 'FIRST') {
        if (message.own) {
          return `${roundedBorderRadius} ${roundedBorderRadius} ${defaultBorderRadius} ${roundedBorderRadius}`
        } else {
          return `${roundedBorderRadius} ${roundedBorderRadius} ${roundedBorderRadius} ${defaultBorderRadius}`
        }
      }
      if (message.consecutivePosition === 'MIDDLE') {
        if (message.own) {
          return `${roundedBorderRadius} ${defaultBorderRadius} ${defaultBorderRadius} ${roundedBorderRadius}`
        } else {
          return `${defaultBorderRadius} ${roundedBorderRadius} ${roundedBorderRadius} ${defaultBorderRadius}`
        }
      }
      if (message.consecutivePosition === 'LAST') {
        if (message.own) {
          return `${roundedBorderRadius} ${defaultBorderRadius} ${roundedBorderRadius}  ${roundedBorderRadius}`
        } else {
          return `${defaultBorderRadius} ${roundedBorderRadius} ${roundedBorderRadius} ${roundedBorderRadius}`
        }
      }
    } else {
      return roundedBorderRadius
    }
  }

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      flexDirection={message.own ? 'row-reverse' : 'row'}
      alignItems="flex-end"
    >
      <Box sx={{ width: message.own ? 10 : 40 }} ml={tiny ? 0.5 : 2}>
        {!message.own && ((message.isConsecutive && message.consecutivePosition === 'LAST') || !message.isConsecutive) && (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: isUserOnline() ? '#44b700' : '#be0000',
                color: isUserOnline() ? '#44b700' : '#be0000',
                boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
                '&::after': {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  border: '1px solid currentColor',
                  content: '""',
                },
              },
            }}
          >
            <ProfileAvatar
              avatar={user?.avatar && `${API_URL}/media/${user?.avatar}`}
              firstName={user?.firstName}
              lastName={user?.lastName}
              showTooltip={true}
              tooltipTitle={`${user?.firstName} ${user?.lastName}, ${isUserOnline() ? 'Online' : 'Offline'}`}
              sx={{ cursor: 'default', width: 36, height: 36 }}
            />
          </Badge>
        )}
      </Box>
      <Box display="flex" alignItems={message.own ? 'flex-end' : 'flex-start'} flexDirection="column">
        <Tooltip
          enterDelay={300}
          placement={message.own ? 'left' : 'right'}
          title={
            DateTime.fromMillis(message.date).toISODate() === DateTime.local().toISODate()
              ? localizedDateTimeInMillisRoomTz(message.date, { timeStyle: 'short' })
              : localizedDateTimeInMillisRoomTz(message.date, { timeStyle: 'short', dateStyle: 'short' })
          }
        >
          <Box
            sx={{
              bgcolor: message.own ? 'primary.lighter' : theme.palette.mode === 'dark' ? '#333d49' : 'grey.200',
              maxWidth: tiny ? 210 : 400,
              color: message.own ? 'primary.darker' : 'text.primary',
              mt: 0.125,
              py: tiny ? 1.125 : 1.25,
              px: tiny ? 1.5 : 1.75,
              borderRadius: setBorderRadius(),
            }}
          >
            <Typography variant="body2" sx={{ wordWrap: 'break-word' }}>
              {checkText(message.data)}
            </Typography>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  )
}

export default memo(Message)
