import { AddAPhoto, Upload } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Tooltip, Typography } from '@mui/material'
import { MAX_FILES } from '../../../Status/components/PlayedReservationDialog/PlayedReservationDialog'
import { useTranslation } from 'react-i18next'
import { PhotoBuilder } from 'components'
import { useState } from 'react'

export default function AttachFiles({ open, getInputProps, previews, reservation, mutatePhotos }) {
  const { t } = useTranslation('reservations')

  const [isCreateOpen, setIsCreateOpen] = useState(false)

  return (
    <>
      <Typography variant="overline">{t('AttachFile', { max: MAX_FILES })}</Typography>
      <ButtonGroup disableElevation variant="outlined">
        <Button startIcon={<AddAPhoto />} onClick={() => setIsCreateOpen(true)}>
          {t('translation:Open')} {t('photos:PhotoBuilder')}
        </Button>
        <Tooltip title={t('IfDontWantToEditPhotos')}>
          <Button startIcon={<Upload />} onClick={open}>
            {t('translation:UploadFromDevice')}
            <input {...getInputProps()} />
          </Button>
        </Tooltip>
      </ButtonGroup>
      <Box display="flex" flexWrap="wrap">
        {previews}
      </Box>
      <PhotoBuilder
        isOpen={isCreateOpen}
        setIsOpen={setIsCreateOpen}
        passedReservation={reservation}
        onCreate={mutatePhotos}
      />
    </>
  )
}
