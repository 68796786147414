import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from '@mui/material'
import { useEscapeRooms, useTenantInfo } from 'core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Review, SelectFrame, UploadAfterGamePhoto } from './components'
import { API_URL } from 'config'
import useSWR from 'swr'
import WebFont from 'webfontloader'
import { FRAME_FONTS } from 'config'
import { useError } from 'utils/hooks'
import axios from 'axios'
import { SERVICES_URL } from 'config'
import { base64toBlob, queryParamAsArray } from 'helpers'
import { enqueueSnackbar } from 'notistack'
import { RESERVATION_STATUSES } from 'config'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { PageURLs } from 'Routes'
import { Close } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { mutate } from 'swr'

export default function PhotoBuilder({
  isOpen,
  setIsOpen,
  passedReservation,
  shouldRedirect = false,
  // should be a url
  initialPhoto,
  hideCreateOtherOneOption = false,
  onCreate = () => {},
  onClose = () => {},
}) {
  const { t } = useTranslation('photos')
  const { activeEscapeRooms, updateReservation, activeEscapeRoomsIds } = useEscapeRooms()
  const { activeTenant: tenantId } = useTenantInfo()
  const { setError } = useError()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const escapeRoomIds = useMemo(
    () => (passedReservation ? [passedReservation.key.roomId] : activeEscapeRooms.map((escapeRoom) => escapeRoom.id)),
    [activeEscapeRooms, passedReservation]
  )

  const [frameId, setFrameId] = useState(null)
  const [aspectRatio, setAspectRatio] = useState()
  const [elements, setElements] = useState()
  const [stageDimensions, setStageDimensions] = useState()
  const [activeStep, setActiveStep] = useState(0)
  const [reservation, setReservation] = useState(passedReservation)
  const [completionTime, setCompletionTime] = useState(passedReservation?.completionTime ?? 0)
  const [teamName, setTeamName] = useState(passedReservation?.teamName ?? '')
  const [dataUrl, setDataUrl] = useState(null)
  const [isSaving, setIsSaving] = useState(false)

  const { data: framesData, isLoading, error } = useSWR(
    `${API_URL}/overlay/tenant/${tenantId}?${queryParamAsArray('roomIds', escapeRoomIds)}`,
    {
      fallbackData: [],
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  )

  const handleNext = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep < 0 || prevActiveStep >= steps.length - 1 ? 0 : prevActiveStep + 1
    )
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleClose = () => {
    setIsOpen(false)
    handleStartOver()
    onClose()
  }

  const handleStartOver = () => {
    setActiveStep(0)
    setFrameId(null)
    setAspectRatio()
    setStageDimensions()
    setElements()
    setCompletionTime()
    setTeamName('')
    setDataUrl()
    if (!passedReservation) {
      setReservation()
    }
    setIsSaving(false)
  }

  const handleCreate = async ({ shouldRedirect, shouldCloseModal = false }) => {
    if (!dataUrl) {
      enqueueSnackbar(t('CannotFindImageFile'), { variant: 'error' })
      return
    }

    if (!reservation) {
      enqueueSnackbar(t('NoReservationSelected'), { variant: 'error' })
      return
    }

    setIsSaving(true)

    let formData = new FormData()
    const image = base64toBlob(dataUrl)

    formData.append('image', image)
    formData.append('roomId', reservation.key.roomId)
    formData.append('reservationId', reservation.key.reservationId)

    try {
      const result = await axios.post(`${SERVICES_URL}/team-photos/${tenantId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (
        (teamName !== reservation.teamName || completionTime !== reservation.completionTime) &&
        reservation.status !== RESERVATION_STATUSES['BLOCKED'].key &&
        result.status === 200
      ) {
        await updateReservation(
          { ...reservation, teamName, completionTime },
          reservation.key,
          () => {
            enqueueSnackbar(t('PhotoAndReservationSavedSuccessfully'), { variant: 'success' })
          },
          false
        )
      } else {
        enqueueSnackbar(t('PhotoSavedSuccessfully'), { variant: 'success' })
      }

      setIsSaving(false)
      pathname === PageURLs.Photos &&
        mutate(
          `${API_URL}/${tenantId}/images/with-reservation?asc=false&from=0&pageSize=9&${queryParamAsArray(
            'roomIds',
            activeEscapeRoomsIds
          )}`
        )
      onCreate()

      // TODO: shouldRedirect is not implemented yet as we don't have an instance page for a photo
      // tbf we might not even need one and this implementation could be deleted at some point
      if (shouldRedirect) {
        navigate(`${PageURLs.Photos}/${result.id}`)
      } else if (shouldCloseModal) {
        handleClose()
      }
    } catch (error) {
      setError(error)
    }
  }

  const generateSteps = useCallback(() => {
    const selectFrameStep = {
      id: 'select-frame',
      title: t('SelectFrame'),
      component: (
        <SelectFrame
          framesData={framesData}
          error={error}
          isLoading={isLoading}
          setStageDimensions={setStageDimensions}
          setAspectRatio={setAspectRatio}
          setElements={setElements}
          setActiveStep={setActiveStep}
          setFrameId={setFrameId}
          frameId={frameId}
        />
      ),
    }

    const uploadPhotoStep = {
      id: 'upload-photo',
      title: t('UploadPhoto'),
      component: (
        <UploadAfterGamePhoto
          initialPhoto={initialPhoto}
          setStageDimensions={setStageDimensions}
          stageDimensions={stageDimensions}
          aspectRatio={aspectRatio}
          elements={elements}
          setElements={setElements}
        />
      ),
    }

    const finalizeStep = {
      id: 'finalize',
      title: t('Finalize'),
      component: (
        <Review
          stageDimensions={stageDimensions}
          aspectRatio={aspectRatio}
          elements={elements}
          setElements={setElements}
          reservation={reservation}
          completionTime={completionTime}
          teamName={teamName}
          setReservation={setReservation}
          setCompletionTime={setCompletionTime}
          setTeamName={setTeamName}
          setDataUrl={setDataUrl}
        />
      ),
    }

    let steps = [uploadPhotoStep, finalizeStep]

    if (framesData.length > 1) {
      steps.unshift(selectFrameStep)
    }

    return steps
  }, [
    t,
    framesData,
    error,
    initialPhoto,
    isLoading,
    frameId,
    stageDimensions,
    aspectRatio,
    elements,
    reservation,
    completionTime,
    teamName,
  ])

  const steps = generateSteps()
  const step = steps[activeStep] ?? {}

  useEffect(() => {
    WebFont.load({
      google: {
        families: FRAME_FONTS,
      },
    })
  }, [])

  useEffect(() => {
    // this is when we skip the first step of the stepper and have to set states here
    if (framesData.length === 1 && !stageDimensions && !aspectRatio && !elements) {
      const frameData = JSON.parse(framesData[0].content)
      setStageDimensions(frameData.stageDimensions)
      setAspectRatio(frameData.aspectRatio)
      setElements(frameData.elements.map((element) => ({ ...element, listening: false })))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [framesData, stageDimensions, aspectRatio, elements])

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {t('PhotoBuilder')}
        <IconButton onClick={handleClose} size="small">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '100%' }}>
        {framesData.length > 0 ? (
          <>
            <Stepper activeStep={activeStep}>
              {steps.map((step) => (
                <Step key={step.title}>
                  <StepLabel>{step.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box display="flex" justifyContent="center" mt={2}>
              {step.component}
            </Box>
          </>
        ) : (
          <Typography>
            <Trans i18nKey="photos:NoFrames">
              In order to use the Photo Builder you firstly need to create at least one frame for this escape room. You
              can do it from
              <Link component={RouterLink} to={PageURLs.PhotoFrames}>
                here
              </Link>
            </Trans>
          </Typography>
        )}
      </DialogContent>
      {framesData.length > 0 && (
        <DialogActions>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              {t('translation:Back')}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 ? (
              <Box display="flex" gap={1.5}>
                {!hideCreateOtherOneOption && (
                  <LoadingButton
                    loading={isSaving}
                    onClick={async () => {
                      await handleCreate({ shouldRedirect: false })
                      handleStartOver()
                    }}
                    disabled={!reservation || !dataUrl}
                  >
                    {t('SaveAndCreateAnotherOne')}
                  </LoadingButton>
                )}
                <LoadingButton
                  loading={isSaving}
                  onClick={() => handleCreate({ shouldRedirect, shouldCloseModal: true })}
                  disabled={!reservation || !dataUrl}
                  color="primary"
                  variant="contained"
                >
                  {t('translation:Save')} {t('AndClose').toLowerCase()}
                </LoadingButton>
              </Box>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={
                  steps.length === 3 && activeStep === 0 && (!stageDimensions || !aspectRatio || !elements || !frameId)
                }
                color="primary"
              >
                {t('translation:Next')}
              </Button>
            )}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}
