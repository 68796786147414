import { createContext, useReducer, useEffect, useContext } from 'react'
import { useTenantInfo } from 'core'
import { DateTime, Info } from 'luxon'
import { transformLanguage } from 'helpers'
import { useTranslation } from 'react-i18next'

const dateReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'ESCAPE_ROOM_LOADED':
      const clientOffset = DateTime.now().o * 60000
      const escapeRoomOffset = DateTime.fromObject({}, { zone: payload }).o * 60000
      return {
        ...state,
        timezone: payload,
        clientOffset,
        escapeRoomOffset,
      }
    case 'SWITCH_ESCAPE_ROOM':
      return {
        ...state,
      }
    default:
      return state
  }
}

const initialState = {
  timezone: null,
  clientOffset: null,
  escapeRoomOffset: null,
}

const DateContext = createContext(initialState)

const DateProvider = ({ children }) => {
  const { i18n } = useTranslation()
  const [state, dispatch] = useReducer(dateReducer, initialState)
  const { activeTenantFullInfo, loadingTenants } = useTenantInfo()

  const weekDaysEnglish = Info.weekdays('long', { locale: 'en' })
  const weekDays = Info.weekdays('long', { locale: transformLanguage(i18n?.language) ?? 'en' })

  useEffect(() => {
    !loadingTenants && activeTenantFullInfo && loadDate()
    // eslint-disable-next-line
  }, [loadingTenants, activeTenantFullInfo])

  const loadDate = () => {
    dispatch({
      type: 'ESCAPE_ROOM_LOADED',
      payload: activeTenantFullInfo.zoneId,
    })
  }

  function getTranslatedWeekday(milliseconds, locale = transformLanguage(i18n?.language)) {
    return DateTime.fromMillis(milliseconds).setLocale(locale).toLocaleString({ weekday: 'long' })
  }

  function getWeekdayFromMillis(milliseconds) {
    return DateTime.fromMillis(milliseconds).weekday
  }

  return (
    <DateContext.Provider
      value={{
        timezone: state.timezone,
        clientOffset: state.clientOffset,
        escapeRoomOffset: state.escapeRoomOffset,
        localizedDateTimeInMillisRoomTz: (millis, opts = { timeStyle: 'short' }) =>
          DateTime.fromMillis(isNaN(millis) ? 0 : millis)
            .setLocale(transformLanguage(i18n.language))
            .setZone(state.timezone)
            .toLocaleString(opts),
        // everything below returns utc time zone values
        localizedDateTimeInMillis: (millis, opts = { timeStyle: 'short' }) => {
          return DateTime.fromMillis(isNaN(millis) ? 0 : millis)
            .setLocale(transformLanguage(i18n.language))
            .setZone('utc')
            .toLocaleString(opts)
        },
        todaysDateInMillis: DateTime.fromObject(
          {
            year: DateTime.now().year,
            month: DateTime.now().month,
            day: DateTime.now().day,
          },
          { zone: state.timezone }
        ).valueOf(),
        currentTimeInMillis: DateTime.fromObject(
          {},
          {
            zone: state.timezone,
          }
        ).valueOf(),
        dateTimeInMillis: (millis) => DateTime.fromMillis(millis, { zone: state.timezone }),
        // utils
        weekDays,
        weekDaysEnglish,
        getTranslatedWeekday,
        getWeekdayFromMillis, // from 1 to 7
      }}
      displayName="Date"
    >
      {children}
    </DateContext.Provider>
  )
}

const useDate = () => {
  const context = useContext(DateContext)

  if (context === undefined) {
    throw new Error('useDate can only be used inside DateProvider')
  }

  return context
}

export { DateProvider, useDate }
