import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Box, Button, Divider, Stack, Tooltip } from '@mui/material'
import { RichTextEditor } from 'components'
import { commentInitialState, commentsRichTextFormats, commentsRichTextModules, generateRandomUser } from './helpers'
import { useError } from 'utils/hooks'
import axios from 'axios'
import { LoadingButton } from '@mui/lab'
import { API_URL } from 'config'
import { useCalendar, useTenantInfo } from 'core'
import { Public, PublicOff, EventNote, Person } from '@mui/icons-material'
import { isValidEmail } from 'helpers'
import { Comment } from './components'

export default function Comments({ reservation, comments, isCalendarView = true, mutate = () => {} }) {
  const { setError } = useError()
  const { t } = useTranslation('reservations')
  const { loadCalendar } = useCalendar()
  const { activeTenant: tenantId, activeTenantUsers } = useTenantInfo()
  const commentQuillRef = useRef()

  const fetchLatestComments = () => {
    if (isCalendarView) {
      loadCalendar()
    } else {
      mutate()
    }
  }

  const mixedComments = [...comments?.internalComments, ...comments?.publicComments].sort(
    (a, b) => b.timeCreated - a.timeCreated
  )

  const [comment, setComment] = useState(commentInitialState)
  const [isPublic, setIsPublic] = useState(false)
  const [isForPlayer, setIsForPlayer] = useState(false)

  const [isPosting, setIsPosting] = useState(false)
  const [randomUsers, setRandomUsers] = useState({})

  const isEmailValid = isValidEmail(reservation.player?.email)

  const postComment = async () => {
    try {
      setIsPosting(true)
      await axios.post(`${API_URL}/${tenantId}/comment`, {
        tenantId,
        reservationKey: reservation.key,
        comment,
        isPublic,
        ...(isForPlayer || isPublic ? { playerEmail: reservation.player.email } : {}),
      })
      fetchLatestComments()
      setComment(commentInitialState)
    } catch (error) {
      setError(error)
    } finally {
      setIsPosting(false)
    }
  }

  useEffect(() => {
    const fetchRandomUsers = async () => {
      const users = {}

      for (const comment of comments.publicComments) {
        if (!comment.createdBy || !activeTenantUsers.find(({ username }) => username === comment?.createdBy)) {
          users[comment.id] = await generateRandomUser()
        }
      }

      setRandomUsers(users)
    }

    comments.publicComments.length && Object.keys(randomUsers).length === 0 && fetchRandomUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments.publicComments, randomUsers])

  return (
    <Stack gap={0.5}>
      {!isEmailValid && <Alert severity="warning">{t('InvalidEmailWarning')}</Alert>}
      <RichTextEditor
        modules={commentsRichTextModules}
        formats={commentsRichTextFormats}
        ref={commentQuillRef}
        value={comment}
        onChange={setComment}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" sx={{ flexDirection: { xs: 'column', sm: 'row', alignItems: 'flex-start' } }} gap={0.5}>
          <Tooltip title={t('PublicCommentDescription')}>
            <Button
              size="small"
              disabled={!isEmailValid}
              startIcon={isPublic ? <Public /> : <PublicOff />}
              onClick={() => setIsPublic((state) => !state)}
            >
              {t(isPublic ? 'Public' : 'Internal')}
            </Button>
          </Tooltip>
          <Tooltip title={t('LinkedToReservationDescription')}>
            <Button
              disabled={isPublic || !isEmailValid}
              size="small"
              startIcon={isForPlayer || isPublic ? <Person /> : <EventNote />}
              onClick={() => setIsForPlayer((state) => !state)}
            >
              {t(isForPlayer || isPublic ? 'LinkedToPlayer' : 'LinkedToReservation')}
            </Button>
          </Tooltip>
        </Box>
        <LoadingButton
          loading={isPosting}
          disabled={commentQuillRef?.current?.getText().trim().length === 0 || comment === '<p><br></p>'}
          variant="contained"
          color="primary"
          onClick={postComment}
        >
          {t('CommentAction')}
        </LoadingButton>
      </Box>
      {mixedComments.length > 0 && <Divider sx={{ my: 1 }} />}
      <Stack>
        {mixedComments.map((comment, index) => {
          const existingUser = activeTenantUsers.find(({ username }) => username === comment.createdBy)
          const user = existingUser
            ? {
                firstName: existingUser.firstName,
                lastName: existingUser.lastName,
                avatar: `${API_URL}/media/${existingUser?.avatar}`,
              }
            : randomUsers[comment.id]

          return (
            <Fragment key={comment.id}>
              <Comment comment={comment} user={user} fetchLatestComments={fetchLatestComments} />
              {mixedComments.length !== index + 1 && <Divider sx={{ my: 1 }} />}
            </Fragment>
          )
        })}
      </Stack>
    </Stack>
  )
}
