import palette from 'theme/lightPalette'
import { imageFileResizer } from 'utils/resizer'
import { RESERVATION_STATUSES } from 'config'
import { DateTime } from 'luxon'

export const alpha = (hex = palette.grey[500], alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

const limitNumbersInTextField = (value, max) => {
  if (value.length === 1 && value[0] > max[0]) {
    value = '0' + value
  }

  if (value.length === 2) {
    if (Number(value) === 0) {
      value = '00'
    } else if (value > max) {
      value = max
    }
  }

  return value
}

export const hourMask = (value) => {
  let hours = limitNumbersInTextField(value.substring(0, 2), '23')
  let minutes = limitNumbersInTextField(value.substring(2, 4), '59')

  return `${hours.length ? hours : '00'}:${minutes.length ? minutes : '00'}`
}

export const hhmmToMs = (hour) => {
  return `${hour}:00`.split(':').reduce((acc, time) => 60 * acc + +time) * 1000
}

export const msToHours = (ms, showSeconds = false) => {
  let seconds = Math.floor((ms / 1000) % 60),
    minutes = Math.floor((ms / (1000 * 60)) % 60),
    hours = Math.floor((ms / (1000 * 60 * 60)) % 24)

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds

  if (showSeconds) {
    return hours + ':' + minutes + ':' + seconds
  }

  return hours + ':' + minutes
}

export const getItemRange = ({ itemsOnCurrentPage, totalPages, totalProducts, page }) => {
  if (page <= 0) {
    page = page * -1
  }

  const getPageStart = (itemsOnCurrentPage, page) => {
    return itemsOnCurrentPage * page
  }

  let start
  let end

  if (page === totalPages) {
    start = totalProducts - itemsOnCurrentPage + 1
    end = totalProducts
  } else {
    start = +itemsOnCurrentPage === 0 ? 0 : Math.max(getPageStart(+itemsOnCurrentPage, +page) + 1, 0)
    end = Math.min(getPageStart(+itemsOnCurrentPage, +page + 1), +totalProducts)
  }

  return { start, end }
}

export const sideBarVisibilityRestrictions = {
  HAS_SELECTED_ESCAPE_ROOM: 'HAS_SELECTED_ESCAPE_ROOM',
  HAS_SELECTED_TENANT: 'HAS_SELECTED_TENANT',
  IS_OWNER: 'IS_OWNER',
}

export const INITIAL_YEAR = DateTime.local().year
export const INITIAL_MONTH = DateTime.local().month
export const TODAY = DateTime.utc().startOf('day').valueOf()

export const getScheduleFromDay = (day, schedule) => {
  if (schedule) {
    switch (day) {
      case 1:
        return schedule.MONDAY
      case 2:
        return schedule.TUESDAY
      case 3:
        return schedule.WEDNESDAY
      case 4:
        return schedule.THURSDAY
      case 5:
        return schedule.FRIDAY
      case 6:
        return schedule.SATURDAY
      default:
      case 0:
        return schedule.SUNDAY
    }
  }
  return []
}

export const playersRange = (min, max) => {
  return Array.from({ length: max - min + 1 }, (_, i) => i + min)
}

export const calculateColumnLength = (initialLength, withActions, withCheckbox) => {
  let columnsLength = initialLength

  if (withCheckbox) {
    columnsLength += 1
  }
  if (withActions) {
    columnsLength += 1
  }

  return columnsLength
}

export const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

export const transformTenant = (tenant) => {
  return {
    ...tenant.tenant,
    role: tenant.tenantRole.role,
    rooms: tenant.tenantRooms,
  }
}

export const resizeImage = (file, maxWidth = 1920, maxHeight = 1080, format = 'jpeg') =>
  new Promise((resolve) => {
    imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      format,
      100,
      0,
      (uri) => {
        resolve(uri)
      },
      'file'
    )
  })

export const getReservationColor = (status) => {
  switch (status) {
    case RESERVATION_STATUSES.BLOCKED.key:
      return 'error'
    case RESERVATION_STATUSES.PLAYED.key:
      return 'success'
    case RESERVATION_STATUSES.CANCELED.key:
      return 'error'
    case RESERVATION_STATUSES.CONFIRMED.key:
      return 'info'
    default:
    case RESERVATION_STATUSES.UNCONFIRMED.key:
      return 'warning'
  }
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const onDragEnd = (result, reorderItem, cb) => {
  if (!result.destination) {
    return
  }

  if (result.destination.index === result.source.index) {
    return
  }

  const quotes = reorder(reorderItem, result.source.index, result.destination.index)

  cb(quotes)
}

export const createCronExpression = ({ frequency, month, weekDay, day, hour, minutes, dayValue }) => {
  switch (frequency) {
    case 'everyDay':
      return `0 ${minutes} ${hour} ? * * *`
    case 'everyWeek':
      return `0 ${minutes} ${hour} ? * ${weekDay} *`
    default:
    case 'everyMonth':
      return dayValue === 'specificDate' ? `0 ${minutes} ${hour} ${day || 1} * ? *` : `0 ${minutes} ${hour} L * ? *`
    case 'everyYear':
      return dayValue === 'specificDate'
        ? `0 ${minutes} ${hour} ${day || 1} ${month} ? *`
        : `0 ${minutes} ${hour} L ${month} ? *`
  }
}

export const getReservationAssignmentsForReservation = ({ reservationId, reservationAssignments }) => {
  return reservationAssignments.filter((assignment) => assignment.reservationId === reservationId)
}

export const transformCustomFieldsToKeyValuePairs = (customFields, reservation) => {
  if (reservation !== null && !reservation.additionalFields) {
    reservation.additionalFields = '{}'
  }

  return customFields.reduce(
    (old, field) => ({
      ...old,
      [field.id]:
        field.type === 'checkbox'
          ? reservation !== null
            ? JSON.parse(reservation.additionalFields)[field.id] || false
            : false
          : reservation !== null
          ? JSON.parse(reservation.additionalFields)[field.id] || ''
          : '',
    }),
    {}
  )
}

export const isOnlinePaymentActivatedForRoom = (room) => {
  return room.paymentConfig.onlinePaymentEnabled
}

export const transformOrderEntriesToCartObject = (orderEntries = [], products = []) =>
  !!orderEntries
    ? orderEntries
        .filter((product) => products.map((_product) => _product.id).includes(product.productId))
        .reduce((acc, { productId, quantity }) => {
          if (acc[productId]) {
            acc[productId] += quantity
          } else {
            acc[productId] = quantity
          }
          return acc
        }, {})
    : {}

export const handleDownload = async (url, fileName, cb = () => {}) => {
  try {
    const response = await fetch(url, { mode: 'cors' })
    const blob = await response.blob()
    const urlToDownload = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = urlToDownload
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    a.remove()
    window.URL.revokeObjectURL(urlToDownload)
    cb()
  } catch (error) {
    console.error('Failed to download image', error)
  }
}
