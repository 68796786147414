import { createContext, useReducer, useContext } from 'react'
import { useError } from 'utils/hooks'
import axios from 'axios'
import { useAuth } from '../AuthContext'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { API_URL } from 'config'
import { useCallback } from 'react'

export const defaultConfig = {
  defaultTenant: null,
  calendarAttributes: [],
  reservationTableColumns: [],
  scrollToToday: true,
  showOpenHours: false,
  showOnlyMyHours: false,
  showPaymentStatuses: true,
}

const configurationReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'EDIT_CONFIG':
      return {
        ...state,
        config: { ...state.config, ...payload },
      }
    case 'LOAD_CONFIG':
      return {
        ...state,
        config:
          Object.keys(JSON.parse(payload.config)).length === 0
            ? defaultConfig
            : { ...defaultConfig, ...JSON.parse(payload.config) },
        username: payload.username,
        loadingConfiguration: false,
        hasConfig: true,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loadingConfiguration: payload,
      }
    case 'LOAD_CONFIG_ERROR':
      return {
        ...state,
        config: defaultConfig,
        loadingConfiguration: false,
        hasConfig: false,
      }
    default:
      return state
  }
}

const initialState = {
  config: defaultConfig,
  username: null,
  loadingConfiguration: true,
  hasConfig: false,
}

const ConfigurationContext = createContext(initialState)

const ConfigurationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(configurationReducer, initialState)
  const { setError } = useError()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { user, loadingAuth, isAuthenticated } = useAuth()

  const loadConfiguration = useCallback(async () => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    })

    try {
      const { data } = await axios(`${API_URL}/config/${user.username}`)

      dispatch({
        type: 'LOAD_CONFIG',
        payload: data,
      })
    } catch (error) {
      setError(error)
      dispatch({
        type: 'LOAD_CONFIG_ERROR',
      })
    }
  }, [user, setError])

  useEffect(() => {
    if (!loadingAuth) {
      if (user && isAuthenticated) {
        loadConfiguration()
      } else {
        dispatch({
          type: 'LOAD_CONFIG_ERROR',
        })
      }
    }
  }, [loadingAuth, isAuthenticated, user, loadConfiguration])

  const updateConfiguration = async (change, showSnackbar = true) => {
    try {
      await axios.post(`${API_URL}/config`, {
        config: JSON.stringify({ ...state.config, ...change }),
        username: user.username,
      })

      dispatch({
        type: 'EDIT_CONFIG',
        payload: change,
      })

      showSnackbar && enqueueSnackbar(t('ConfigurationUpdateSuccessful'), { variant: 'success' })
    } catch (error) {
      setError(error)
    }
  }

  return (
    <ConfigurationContext.Provider
      value={{
        config: state.config,
        username: state.username,
        loadingConfiguration: state.loadingConfiguration,
        hasConfig: state.hasConfig,
        updateConfiguration,
        loadConfiguration,
        configDispatch: dispatch,
      }}
      displayName="Configuration"
    >
      {children}
    </ConfigurationContext.Provider>
  )
}

const useConfiguration = () => {
  const context = useContext(ConfigurationContext)

  if (context === undefined) {
    throw new Error('useConfiguration can only be used inside ConfigurationProvider')
  }

  return context
}

export { ConfigurationProvider, useConfiguration }
