import { forwardRef, useEffect, useLayoutEffect, useRef } from 'react'
import Quill from 'quill'
import './ImageResize'
import { Box } from '@mui/material'

const Editor = forwardRef(
  (
    {
      formats = _formats,
      modules = _modules,
      readOnly,
      value,
      onChange,
      onSelectionChange,
      className,
      isMultiLanguage = false,
    },
    ref
  ) => {
    const containerRef = useRef(null)
    const quillRef = useRef(null)
    const valueRef = useRef(value)
    const onChangeRef = useRef(onChange)
    const onSelectionChangeRef = useRef(onSelectionChange)

    useLayoutEffect(() => {
      onChangeRef.current = onChange
      onSelectionChangeRef.current = onSelectionChange
    })

    useEffect(() => {
      if (!containerRef.current) return

      const container = containerRef.current
      const editorContainer = container.appendChild(container.ownerDocument.createElement('div'))
      const quill = new Quill(editorContainer, {
        readOnly,
        theme: 'snow',
        formats,
        modules,
      })

      quillRef.current = quill
      ref.current = quill

      if (valueRef.current) {
        quill.root.innerHTML = valueRef.current
      }

      quill.on(Quill.events.TEXT_CHANGE, () => {
        const html = quill.root.innerHTML
        onChangeRef.current?.(html)
      })

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args)
      })

      return () => {
        ref.current = null
        quillRef.current = null
        container.innerHTML = ''
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref])

    useEffect(() => {
      if (!quillRef.current) return
      const quill = quillRef.current

      if (quill.root.innerHTML !== value) {
        quill.root.innerHTML = value
      }
    }, [value])

    useEffect(() => {
      quillRef.current?.enable(!readOnly)
    }, [readOnly])

    return <Box ref={containerRef} className={isMultiLanguage ? `quill-custom-border ${className}` : className}></Box>
  }
)

const _modules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['link', 'image'],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }],
  ],
  clipboard: {
    matchVisual: false,
  },
  resize: {},
}

const _formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'list',
  'link',
  'indent',
  'image',
  'align',
  'color',
  'background',
]

export default Editor
