import { AvatarGroup, Box, CircularProgress, Divider, IconButton, Typography } from '@mui/material'
import { ProfileAvatar } from 'components'
import { API_URL } from 'config'
import { useChat, useDate, useTenantInfo } from 'core'
import React from 'react'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Message } from './components'
import RefreshIcon from '@mui/icons-material/Refresh'
import { DateTime } from 'luxon'

const ChatContainer = ({ tiny = false }) => {
  const { t } = useTranslation('chat')
  const { localizedDateTimeInMillisRoomTz } = useDate()
  const {
    messages,
    DEFAULT_TIME_TO_RESET,
    loadingPreviousMessages,
    hasNext,
    seen,
    establishedConnection,
    loadingChat,
  } = useChat()
  const { getUserByUsername } = useTenantInfo()

  return (
    <Box display="flex" flexDirection="column" py={tiny ? 1 : 2}>
      {!hasNext && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography align="center" variant="caption">
            {t('WholeChatLoaded')}
          </Typography>
        </Box>
      )}
      {loadingPreviousMessages && (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {messages.map((message, index) => (
        <React.Fragment key={index}>
          {message.date - messages[index - 1]?.date > DEFAULT_TIME_TO_RESET && (
            <Divider>
              <Typography color="textSecondary" variant="caption">
                {DateTime.fromMillis(message.date).toISODate() === DateTime.local().toISODate()
                  ? localizedDateTimeInMillisRoomTz(message.date, { timeStyle: 'short' })
                  : localizedDateTimeInMillisRoomTz(message.date, { timeStyle: 'short', dateStyle: 'short' })}
              </Typography>
            </Divider>
          )}
          <Message message={message} tiny={tiny} />
          {Object.keys(seen).filter((username) => seen[username] === message.date).length > 0 && (
            <Box pt={0.25} pb={1} pr={2}>
              <AvatarGroup
                max={6}
                sx={{
                  '& .MuiAvatarGroup-avatar': {
                    fontSize: '0.9rem',
                    width: 20,
                    height: 20,
                  },
                }}
              >
                {Object.keys(seen)
                  .filter((username) => seen[username] === message.date)
                  .map((username) => {
                    const user = getUserByUsername(username)
                    return (
                      <ProfileAvatar
                        key={username}
                        width="20px"
                        height="20px"
                        showTooltip={true}
                        cursor="default"
                        firstName={user?.firstName}
                        lastName={user?.lastName}
                        src={user?.avatar && `${API_URL}/media/${user?.avatar}`}
                        style={{ fontSize: '0.9rem' }}
                      />
                    )
                  })}
              </AvatarGroup>
            </Box>
          )}
        </React.Fragment>
      ))}
      {!establishedConnection && !loadingChat && (
        <Typography
          variant="caption"
          color="error"
          align="center"
          component="div"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          {t('ConnectionLost')}.{' '}
          <IconButton size="small" color="error" onClick={() => window.location.reload(false)}>
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Typography>
      )}
    </Box>
  )
}

export default memo(ChatContainer)
