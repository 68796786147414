import { API_URL } from 'config'
import { useTenantInfo } from 'core'
import { usePagination } from 'layouts/Main/components/Table/components/TablePagination/usePagination'
import useSWR from 'swr'

export const useActionHistory = ({ reservationId, roomId, type, username, pause = false }) => {
  const { activeTenant: tenantId } = useTenantInfo()
  const {
    from,
    pageSize,
    asc,
    hasNext,
    setAsc,
    setCountClicks,
    setFrom,
    setPageSize,
    setPaginationData,
    startNextOrPreviousPageFrom,
    countClicks,
  } = usePagination({ paginateBy: 'id' })

  const { data, isLoading, error } = useSWR(
    !pause &&
      `${API_URL}/${tenantId}/action-history?asc=${asc}&from=${from}&pageSize=${pageSize}${
        reservationId !== undefined ? `&reservationId=${reservationId}` : ''
      }${roomId !== undefined ? `&roomId=${roomId}` : ''}${type !== undefined ? `&type=${type}` : ''}${
        username !== undefined ? `&username=${username}` : ''
      }`,
    {
      fallbackData: { hasNext: false, total: 0, data: [] },
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    }
  )

  return {
    actionHistory: data,
    isLoading,
    error,
    hasNext,
    setAsc,
    setCountClicks,
    setFrom,
    setPageSize,
    setPaginationData,
    startNextOrPreviousPageFrom,
    countClicks,
    asc,
    from,
    pageSize,
  }
}
