import { Box } from '@mui/material'
import { useTenantInfo } from 'core'
import { Product } from './components'

export default function AddRemoveProductsFromCart({
  roomId,
  cart,
  setCart,
  disableRemoveButton = false,
  disableAddButton = false,
  initialCart = {},
}) {
  const { getProductsForRoom } = useTenantInfo()
  const products = getProductsForRoom(roomId)

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {products.map((product) => {
        return (
          <Product
            key={product.id}
            product={product}
            initialCart={initialCart}
            cart={cart}
            setCart={setCart}
            disableRemoveButton={disableRemoveButton}
            disableAddButton={disableAddButton}
          />
        )
      })}
    </Box>
  )
}
