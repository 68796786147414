import { useEscapeRooms, useTenantInfo } from 'core'
import { calculateFinalPrice, calculateFinalPriceFromProducts, calculateFinalPriceWithGiftCard } from 'utils/calculate'
import useSWR from 'swr'
import { API_URL, FINANCE_TYPES, INCOME_TRANSACTION_TYPES, RESERVATION_STATUSES, TRANSACTION_TYPES } from 'config'
import { transformLanguage } from 'helpers'
import { useTranslation } from 'react-i18next'

export default function usePricing({ reservation = null, giftCard, cart = null, numberOfPlayers } = {}) {
  const { i18n } = useTranslation()
  const { activeTenantFullInfo, flattenedProducts } = useTenantInfo()
  const { escapeRooms: rooms } = useEscapeRooms()
  const reservationId = reservation?.key?.reservationId
  const roomId = reservation?.key?.roomId
  const gameMode = reservation?.bookingInfo?.gameMode

  const { data: existingFinancesResponse, isLoading, mutate } = useSWR(
    reservation &&
      `${API_URL}/finances/room/${roomId}/reservation/${reservationId}?financeType=INCOME&financeType=EXPENSE`,
    {
      fallbackData: [],
      shouldRetryOnError: false,
    }
  )

  const existingFinancesResponseWithoutFullPaymentAndProducts = existingFinancesResponse.filter((finance) =>
    reservation?.status === RESERVATION_STATUSES.PLAYED.key
      ? finance.transactionType !== TRANSACTION_TYPES.FULL_PAYMENT.key &&
        finance.transactionType !== TRANSACTION_TYPES.PRODUCT.key
      : true
  )

  // Incomes exclude "Standard" transactions
  const incomes = existingFinancesResponseWithoutFullPaymentAndProducts
    .filter((finance) => finance.financeType === FINANCE_TYPES['INCOME'].key)
    .filter((finance) => finance.transactionType !== TRANSACTION_TYPES.NONE.key)
    .map((finance) => finance.amount)
    .reduce((acc, val) => acc + val, 0)

  const incomesWithStandard = existingFinancesResponseWithoutFullPaymentAndProducts
    .filter((finance) => finance.financeType === FINANCE_TYPES['INCOME'].key)
    .map((finance) => finance.amount)
    .reduce((acc, val) => acc + val, 0)

  // Expenses include only refunds (not salaries, etc.)
  const expenses = existingFinancesResponse
    .filter((finance) => finance.financeType === FINANCE_TYPES['EXPENSE'].key)
    .filter(
      (finance) =>
        finance.transactionType === INCOME_TRANSACTION_TYPES['REFUND_AT_VENUE'].key ||
        finance.transactionType === INCOME_TRANSACTION_TYPES['REFUND_ONLINE'].key
    )
    .map((finance) => finance.amount)
    .reduce((acc, val) => acc + val, 0)

  const priceFromExistingFinances = incomes - expenses
  const priceFromExistingFinancesWithStandard = incomesWithStandard - expenses
  const room = rooms.find((room) => room.id === roomId)
  const validProducts = cart ? Object.keys(cart).filter((productId) => cart[productId] > 0) : []
  const productsPrices = validProducts.map(
    (productId) => flattenedProducts.find((product) => productId === product.id).price * cart[productId]
  )
  const selectedGameMode = gameMode ? gameMode : { uid: null, priceVariation: 0 }

  const productsTotal = calculateFinalPriceFromProducts(productsPrices)
  const priceWithGiftCard = calculateFinalPriceWithGiftCard(
    numberOfPlayers,
    room,
    giftCard,
    priceFromExistingFinances,
    selectedGameMode
  )
  const subtotal = isLoading
    ? 0
    : numberOfPlayers
    ? calculateFinalPrice(numberOfPlayers, room, null, productsPrices, selectedGameMode, 0)
    : 0
  const balanceDue = isLoading
    ? 0
    : numberOfPlayers
    ? calculateFinalPrice(numberOfPlayers, room, giftCard, productsPrices, selectedGameMode, priceFromExistingFinances)
    : 0

  return {
    balanceDue,
    subtotal,
    selectedGameMode,
    productsPrices,
    validProducts,
    priceFromExistingFinances,
    priceFromExistingFinancesWithStandard,
    isFinancesLoading: isLoading,
    validProductsArray: validProducts.map((productId) => ({ productId, quantity: cart[productId] })),
    productsTotal,
    incomes,
    expenses,
    depositTotal: room?.depositAmount + productsTotal + selectedGameMode.priceVariation,
    priceWithGiftCard,
    existingFinances: existingFinancesResponseWithoutFullPaymentAndProducts,
    existingFinancesRaw: existingFinancesResponse,
    mutateFinances: mutate,
    displayCurrency: (number) =>
      new Intl.NumberFormat(transformLanguage(i18n?.language), {
        style: 'currency',
        currency: activeTenantFullInfo?.currency,
      }).format(number),
  }
}
