import { Box } from '@mui/material'
import { CustomChip } from 'components'
import { useEscapeRooms } from 'core'
import { useTranslation } from 'react-i18next'

export default function EscapeRooms({ passedEscapeRooms }) {
  const { t } = useTranslation()
  const { escapeRooms } = useEscapeRooms()

  return (
    <Box display="inline-flex" gap={0.75} flexWrap="wrap">
      {passedEscapeRooms.length === escapeRooms.length
        ? t('AllEscapeRooms')
        : passedEscapeRooms.map((roomId) => {
            const escapeRoom = escapeRooms?.find((escapeRoom) => escapeRoom.id === roomId)

            return (
              escapeRoom?.name && (
                <CustomChip
                  key={roomId}
                  size="small"
                  variant="outlined"
                  color={escapeRoom.additionalInfo.roomColor}
                  label={escapeRoom?.name}
                />
              )
            )
          })}
    </Box>
  )
}
