import axios from 'axios'
import { ACCEPTED_ADMIN_GROUPS } from 'config'
import { API_URL } from 'config'
import { createContext, useReducer, useContext, useEffect, useCallback } from 'react'
import { transformTenant } from 'utils'
import { useError } from 'utils/hooks'
import { useAuth } from '../AuthContext'
import { useConfiguration } from '../ConfigurationContext'

const tenantInfoReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOAD_TENANT_INFO':
      return {
        ...state,
        tenants: payload.tenants,
        activeTenant: payload.activeTenant,
        activeTenantFullInfo: payload.tenants.find((tenant) => tenant.id === payload.activeTenant),
        activeTenantUsers: payload.activeTenantUsers,
        currentUserRoleInTenant: payload.currentUserRoleInTenant,
        stripeData: payload.stripeData,
        products: payload.productsData,
        emailTemplates: payload.emailTemplatesData,
        loadingTenants: false,
      }
    case 'LOAD_TENANT_INFO_ADMIN':
      return {
        ...state,
        tenants: payload,
        loadingTenants: false,
      }
    case 'LOAD_TENANT_INFO_ERROR':
      return {
        ...state,
        tenants: [],
        loadingTenants: false,
        activeTenant: null,
        activeTenantFullInfo: null,
        activeTenantUsers: null,
        currentUserRoleInTenant: null,
        products: [],
        emailTemplates: [],
        stripeData: null,
      }
    case 'SET_ACTIVE_TENANT':
      return {
        ...state,
        activeTenant: payload,
        activeTenantFullInfo: state.tenants.find((tenant) => tenant.id === payload),
      }
    case 'SET_PRODUCTS':
      return {
        ...state,
        products: payload,
      }
    case 'SET_EMAIL_TEMPLATES':
      return {
        ...state,
        emailTemplates: payload,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loadingTenants: payload,
      }
    case 'SET_ACTIVE_TENANT_USERS':
      return {
        ...state,
        activeTenantUsers: payload.activeTenantUsers,
        currentUserRoleInTenant: payload.currentUserRoleInTenant,
      }
    case 'SET_LOADED_TENANTS':
      return {
        ...state,
        tenants: payload,
      }
    default:
      return state
  }
}

const initialState = {
  tenants: [],
  loadingTenants: true,
  activeTenant: null,
  activeTenantFullInfo: null,
  activeTenantUsers: null,
  currentUserRoleInTenant: null,
  products: [],
  emailTemplates: [],
  stripeData: null,
}

const TenantInfoContext = createContext(initialState)

const TenantInfoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(tenantInfoReducer, initialState)
  const { isAuthenticated, loadingAuth, user } = useAuth()
  const { config, loadingConfiguration, hasConfig } = useConfiguration()
  const { setError } = useError()

  const fetchTenantData = async (activeTenant) => {
    const endpoints = [
      `${API_URL}/tenant/${activeTenant}/users`,
      `${API_URL}/${activeTenant}/product`,
      `${API_URL}/email-template/tenant/${activeTenant}`,
      `${API_URL}/tenant/roles/${activeTenant}`,
      `${API_URL}/stripe-connected-account/${activeTenant}/details-submitted`,
    ]

    const responses = await Promise.allSettled(endpoints.map((url) => axios(url)))

    return {
      users: responses[0]?.value?.data,
      products: responses[1]?.value?.data ?? initialState.products,
      emailTemplates: responses[2]?.value?.data ?? initialState.emailTemplates,
      roles: responses[3]?.value?.data,
      stripeData: responses[4]?.value?.data ?? initialState.stripeData,
    }
  }

  const loadTenantInfo = useCallback(async () => {
    dispatch({
      type: 'SET_LOADING',
      payload: true,
    })

    if (user?.username) {
      try {
        if (ACCEPTED_ADMIN_GROUPS.includes(user.userGroup)) {
          const { data: tenantData } = await axios(`${API_URL}/tenant/info/all`)
          const activeTenant =
            state.activeTenant || config.defaultTenant === 'last-selected-tenant'
              ? localStorage.getItem('last-selected-company')
              : config.defaultTenant || localStorage.getItem('last-selected-company') || null

          if (activeTenant) {
            const tenantDetails = await fetchTenantData(activeTenant)

            const payload = {
              tenants: tenantData?.map(transformTenant),
              activeTenant,
              activeTenantUsers: tenantDetails.users?.map((user) => ({
                ...user,
                role: tenantDetails.roles?.find((role) => role.username.toLowerCase() === user.username.toLowerCase())
                  ?.role,
              })),
              currentUserRoleInTenant: tenantDetails.roles?.find(
                (role) => role.username.toLowerCase() === user.username.toLowerCase()
              )?.role,
              stripeData: tenantDetails.stripeData,
              emailTemplatesData: tenantDetails.emailTemplates,
              productsData: tenantDetails.products,
            }

            dispatch({
              type: 'LOAD_TENANT_INFO',
              payload,
            })
          } else {
            dispatch({
              type: 'LOAD_TENANT_INFO_ADMIN',
              payload: tenantData?.map((tenant) => transformTenant(tenant)),
            })
          }
        } else {
          const { data: tenantData } = await axios(`${API_URL}/tenant/user/${user.username}`)
          const activeTenant = state.activeTenant || config.defaultTenant || tenantData[0]?.tenant?.id

          if (!activeTenant) {
            dispatch({
              type: 'LOAD_TENANT_INFO_ERROR',
            })
            return
          }

          const tenantDetails = await fetchTenantData(activeTenant)

          const payload = {
            tenants: tenantData.map(transformTenant),
            activeTenant,
            activeTenantUsers: tenantDetails.users?.map((user) => ({
              ...user,
              role: tenantDetails.roles?.find((role) => role.username.toLowerCase() === user.username.toLowerCase())
                ?.role,
            })),
            currentUserRoleInTenant: tenantDetails.roles?.find(
              (role) => role.username.toLowerCase() === user.username.toLowerCase()
            )?.role,
            stripeData: tenantDetails.stripeData,
            productsData: tenantDetails.products,
            emailTemplatesData: tenantDetails.emailTemplates,
          }

          dispatch({
            type: 'LOAD_TENANT_INFO',
            payload,
          })
        }
      } catch (error) {
        dispatch({
          type: 'LOAD_TENANT_INFO_ERROR',
        })
      }
    } else {
      dispatch({
        type: 'LOAD_TENANT_INFO_ERROR',
      })
    }
  }, [user, config, state.activeTenant])

  useEffect(() => {
    if (!loadingConfiguration) {
      if (hasConfig) {
        user && loadTenantInfo()
      } else {
        dispatch({
          type: 'LOAD_TENANT_INFO_ERROR',
        })
      }
    }
    // eslint-disable-next-line
  }, [loadingAuth, isAuthenticated, loadingConfiguration, hasConfig, user])

  const getTenantUsers = useCallback(
    async (newTenant = null) => {
      try {
        const { data: usersData } = await axios(`${API_URL}/tenant/${newTenant ?? state.activeTenant}/users`)
        const { data: rolesData } = await axios(`${API_URL}/tenant/roles/${newTenant ?? state.activeTenant}`)

        dispatch({
          type: 'SET_ACTIVE_TENANT_USERS',
          payload: {
            activeTenantUsers: usersData.map((user) => {
              const userRole = rolesData.find((role) => role.username.toLowerCase() === user.username.toLowerCase())

              return { ...user, role: userRole.role }
            }),
            currentUserRoleInTenant: !ACCEPTED_ADMIN_GROUPS.includes(user.userGroup)
              ? rolesData.find((role) => role.username.toLowerCase() === user.username.toLowerCase())?.role
              : 'OWNER',
          },
        })
      } catch (error) {
        setError(error)
      }
    },
    [setError, state.activeTenant, user?.userGroup, user?.username]
  )

  const setActiveTenant = useCallback(
    async (tenant) => {
      try {
        const tenantDetails = await fetchTenantData(tenant)

        dispatch({
          type: 'SET_ACTIVE_TENANT',
          payload: tenant,
        })

        dispatch({
          type: 'SET_ACTIVE_TENANT_USERS',
          payload: {
            activeTenantUsers: tenantDetails.users?.map((user) => ({
              ...user,
              role: tenantDetails.roles?.find((role) => role.username.toLowerCase() === user.username.toLowerCase())
                ?.role,
            })),
            currentUserRoleInTenant: tenantDetails.roles?.find(
              (role) => role.username.toLowerCase() === user.username.toLowerCase()
            )?.role,
          },
        })

        dispatch({
          type: 'SET_PRODUCTS',
          payload: tenantDetails.products,
        })

        dispatch({
          type: 'SET_EMAIL_TEMPLATES',
          payload: tenantDetails.emailTemplates,
        })

        dispatch({
          type: 'SET_STRIPE_DATA',
          payload: tenantDetails.stripeData,
        })
      } catch (error) {
        setError(error)
      }
    },
    [setError, user?.username]
  )

  const refetchProducts = useCallback(async () => {
    try {
      const { data } = await axios(`${API_URL}/${state.activeTenant}/product`)

      dispatch({
        type: 'SET_PRODUCTS',
        payload: data,
      })
    } catch (error) {
      setError(error)
    }
  }, [setError, state.activeTenant])

  const refetchEmailTemplates = useCallback(async () => {
    try {
      const { data } = await axios(`${API_URL}/email-template/tenant/${state.activeTenant}`)

      dispatch({
        type: 'SET_EMAIL_TEMPLATES',
        payload: data,
      })
    } catch (error) {
      setError(error)
    }
  }, [setError, state.activeTenant])

  const addEmployee = async (employee) => {
    try {
      await axios.post(`${API_URL}/tenant/roles`, employee)
      await getTenantUsers()
    } catch (error) {
      setError(error)
    }
  }

  const deleteEmployee = async (username) => {
    try {
      await axios.delete(`${API_URL}/tenant/roles/${state.activeTenant}/${username}`)
      await getTenantUsers()
    } catch (error) {
      setError(error)
    }
  }

  const changeEmployeeRole = async (role, username) => {
    const request = {
      tenantId: state.activeTenant,
      role,
      username,
    }
    try {
      await axios.put(`${API_URL}/tenant/roles/`, request)
      await getTenantUsers()
    } catch (error) {
      setError(error)
    }
  }

  const editTenant = async (request) => {
    try {
      await axios.put(`${API_URL}/tenant`, {
        ...request,
        active: state.activeTenantFullInfo.active,
        id: state.activeTenant,
        owner: state.activeTenantFullInfo.owner,
      })
      await loadTenantInfo()
    } catch (error) {
      setError(error)
    }
  }

  const getUserById = (userId) => {
    return state.activeTenantUsers.find((user) => user.id === userId)
  }

  const getUserByUsername = (username) => {
    if (!state.activeTenantUsers) {
      return
    }
    return state.activeTenantUsers.find((user) => user.username === username)
  }

  const getProductsForRoom = (roomId, products = state.products) => {
    return products.filter((product) => product.relatedRoomIds.includes(roomId))
  }

  return (
    <TenantInfoContext.Provider
      value={{
        tenants: state.tenants,
        products: state.products,
        flattenedProducts: [
          ...state.products,
          ...state.products.flatMap((p) =>
            p.productVariants.map((product) => ({
              ...product,
              name: `${
                state.products.find((mainProduct) => mainProduct.id === product.mainProductId)?.name ??
                'Deleted product'
              } - ${product.name}`,
            }))
          ),
        ], // main products + variant products
        stripeData: state.stripeData,
        activeTenant: state.activeTenant,
        loadingTenants: state.loadingTenants,
        emailTemplates: state.emailTemplates,
        activeTenantUsers: state.activeTenantUsers,
        activeTenantFullInfo: state.activeTenantFullInfo,
        currentUserRoleInTenant: state.currentUserRoleInTenant,
        isStripeActivated: !!state.stripeData
          ? state.stripeData?.detailsSubmitted && state.stripeData?.chargesEnabled
          : false,
        editTenant,
        getUserById,
        addEmployee,
        deleteEmployee,
        getTenantUsers,
        loadTenantInfo,
        setActiveTenant,
        refetchProducts,
        getUserByUsername,
        getProductsForRoom,
        changeEmployeeRole,
        refetchEmailTemplates,
      }}
      displayName="Tenant Info"
    >
      {children}
    </TenantInfoContext.Provider>
  )
}

const useTenantInfo = () => {
  const context = useContext(TenantInfoContext)

  if (context === undefined) {
    throw new Error('useTenantInfo can only be used inside TenantInfoProvider')
  }

  return context
}

export { TenantInfoProvider, useTenantInfo }
