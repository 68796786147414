import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material'
import { useEscapeRooms } from 'core'
import { useTranslation } from 'react-i18next'

export default function EscapeRoomPickerField({ roomIds, setRoomIds, size = 'medium' }) {
  const { t } = useTranslation()
  const { escapeRooms } = useEscapeRooms()

  return (
    <FormControl sx={{ width: '100%' }} size={size}>
      <InputLabel id="escaperoom-ids" required>
        {t('translation:EscapeRooms')}
      </InputLabel>
      <Select
        labelId="escaperoom-ids"
        multiple
        required
        fullWidth
        name="roomIds"
        value={roomIds}
        onChange={(e) => setRoomIds(typeof value === 'string' ? e.target.value.split(',') : e.target.value)}
        input={<OutlinedInput required label={t('translation:EscapeRooms')} />}
        renderValue={(selectedEscapeRooms) =>
          escapeRooms
            .filter((escapeRoom) => selectedEscapeRooms.includes(escapeRoom.id))
            .map((escapeRoom) => escapeRoom.name)
            .join(', ')
        }
      >
        {escapeRooms.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            <Checkbox checked={roomIds.indexOf(id) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
