import axios from 'axios'
import { API_URL } from 'config'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { resizeImage } from 'utils'
import { v4 as uuid } from 'uuid'
import useError from './useError'
import { File } from 'layouts/Main/components/UploadFiles/components'

const useMultipleUpload = ({
  tenantId,
  format,
  maxFiles = 10,
  url = `${API_URL}/media/multiple`,
  instantRevoke = false,
  keepAlreadyUploadedFiles = false,
}) => {
  const { setError } = useError()
  const { enqueueSnackbar } = useSnackbar()

  const [files, setFiles] = useState([])
  const [response, setResponse] = useState(null)

  const { getInputProps, open, getRootProps } = useDropzone({
    accept: 'image/*',
    maxFiles,
    maxSize: 30000000, // 30MB
    multiple: true,
    onDropRejected: (fileRejections) => {
      fileRejections.map((file) => enqueueSnackbar(file.errors[0].message, { variant: 'error' }))
    },
    onDropAccepted: async (acceptedFiles) => {
      if (acceptedFiles.length + files.length > maxFiles) {
        enqueueSnackbar(`Max ${maxFiles} files`, { variant: 'error' })
        return
      }

      const resizedFiles = await Promise.all(
        acceptedFiles.map((file) => {
          const hardcodedFormat = file.name.split('.').at(-1) === 'png' ? 'png' : 'jpg'

          Object.defineProperty(file, 'name', {
            writable: true,
            value: tenantId
              ? `${tenantId}/${uuid()}.${format ?? hardcodedFormat}`
              : `${uuid()}.${format ?? hardcodedFormat}`,
          })

          return resizeImage(file, 1920, 1080, format ?? hardcodedFormat)
        })
      )

      setFiles((initialFiles) => [
        ...(keepAlreadyUploadedFiles ? initialFiles : []),
        ...resizedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        }),
      ])
    },
  })

  const thumbs = files.map((file) => (
    <File key={file.name} imgSrc={file.preview} removeFile={() => removeFile(file.name)} />
  ))

  let formData = new FormData()

  useEffect(
    () => () => {
      if (instantRevoke) {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file) => URL.revokeObjectURL(file.preview))
      }
    },
    [files, instantRevoke]
  )

  const removeFile = (fileName) => {
    setFiles(files.filter((file) => file.name !== fileName))
  }

  const handleUpload = async () => {
    files.forEach((file) => formData.append('file', file))

    try {
      const { data } = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      setResponse(data)
      return data
    } catch (error) {
      setError(error)
    }
  }

  return { handleUpload, open, getInputProps, getRootProps, files, previews: thumbs, response, removeFile, setFiles }
}

export default useMultipleUpload
