import { Box, Typography } from '@mui/material'
import { FieldType } from '../../DisplayValue'

const AdditionalField = ({ row, column, value, additionalFieldValue, setIsUpdated, t }) => {
  return (
    <Box>
      <Typography component="div" sx={{ fontSize: 14, fontWeight: 400 }}>
        {FieldType({ column, value, row, setIsUpdated, t })}
      </Typography>
      <Typography component="div" sx={{ fontSize: 14, fontWeight: 400 }} color="textSecondary">
        {FieldType({ column, value: additionalFieldValue, row, isAdditionalField: true, setIsUpdated, t })}
      </Typography>
    </Box>
  )
}

export default AdditionalField
