import axios from 'axios'
import { API_URL } from 'config'
import { queryParamAsArray } from 'helpers'
import { createContext, useReducer, useContext, useEffect } from 'react'
import { useError } from 'utils/hooks'
import { useAuth } from '../AuthContext'
import { useTenantInfo } from '../TenantInfoContext'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const escapeRoomsReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'LOAD_ESCAPE_ROOMS':
      return {
        ...state,
        escapeRooms: payload,
      }
    case 'LOAD_INITIAL_ESCAPE_ROOMS':
      return {
        ...state,
        escapeRooms: payload.escapeRooms,
        activeEscapeRooms: payload.activeEscapeRooms
          .map((escapeRoomId) => payload.escapeRooms.find((escapeRoom) => escapeRoom.id === escapeRoomId))
          .sort(function (a, b) {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }

            return 0
          }),
        activeEscapeRoomsIds: payload.activeEscapeRooms,
        loadingEscapeRooms: false,
      }
    case 'LOAD_ESCAPE_ROOMS_ERROR':
      return {
        ...state,
        escapeRooms: [],
        loadingEscapeRooms: false,
        activeEscapeRooms: [],
        activeEscapeRoomsIds: [],
      }
    case 'SET_ACTIVE_ROOMS':
      const { escapeRooms, tenantId } = payload
      let activeEscapeRooms
      if (!Array.isArray(escapeRooms)) {
        activeEscapeRooms = state.escapeRooms.length ? state.escapeRooms : []
      } else {
        if (escapeRooms.length === 0) {
          activeEscapeRooms = []
        } else {
          activeEscapeRooms = escapeRooms.sort(function (a, b) {
            const nameA = a.name.toUpperCase()
            const nameB = b.name.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }

            return 0
          })
        }
      }
      activeEscapeRooms.length
        ? localStorage.setItem(
            `activeEscapeRoomsIds-${tenantId}`,
            JSON.stringify(activeEscapeRooms.map((escapeRoom) => escapeRoom.id))
          )
        : localStorage.removeItem(`activeEscapeRoomIds-${tenantId}`)
      return {
        ...state,
        activeEscapeRooms,
        activeEscapeRoomsIds: activeEscapeRooms.length ? activeEscapeRooms.map((escapeRoom) => escapeRoom.id) : [],
        loadingEscapeRooms: false,
      }
    case 'SET_LOADING_ESCAPE_ROOMS':
      return {
        ...state,
        loadingEscapeRooms: payload,
      }
    case 'EDIT_ESCAPE_ROOM':
      return {
        ...state,
        escapeRooms: state.escapeRooms.map((escapeRoom) =>
          escapeRoom.id === payload.id ? { ...escapeRoom, ...payload } : escapeRoom
        ),
      }
    default:
      return state
  }
}

const initialState = {
  escapeRooms: [],
  loadingEscapeRooms: true,
  activeEscapeRooms: [],
  activeEscapeRoomsIds: [],
}

const EscapeRoomsContext = createContext(initialState)

const EscapeRoomsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(escapeRoomsReducer, initialState)
  const { t } = useTranslation()
  const { setError } = useError()
  const { activeTenant, loadingTenants } = useTenantInfo()
  const { isAuthenticated } = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!loadingTenants) {
      if (activeTenant) {
        loadEscapeRoomsInfo()
      } else {
        dispatch({
          type: 'LOAD_ESCAPE_ROOMS_ERROR',
        })
      }
    }
    // eslint-disable-next-line
  }, [activeTenant, isAuthenticated, loadingTenants])

  const updateReservation = async (
    updatedReservation,
    reservationKey,
    additionalFunction = async () => {},
    showSnackbar = true
  ) => {
    try {
      const { data } = await axios.put(`${API_URL}/reservation`, { reservationKey, updatedReservation })
      await additionalFunction()
      showSnackbar &&
        enqueueSnackbar(t('reservations:ReservationUpdatedSuccessfully'), {
          variant: 'success',
        })
      return data
    } catch (error) {
      setError(error)
      return false
    }
  }

  const deleteReservation = async ({ reservationId, roomId, closeModal = () => {}, updateInstances = () => {} }) => {
    try {
      await axios.delete(`${API_URL}/reservation/${roomId}/${reservationId}`)
      closeModal()
      updateInstances()
    } catch (error) {
      setError(error)
    }
  }

  const createReservation = async (
    reservation,
    depositAmount = 0,
    closeModal = () => {},
    updateInstances = () => {}
  ) => {
    try {
      delete reservation.key.reservationId

      await axios.post(`${API_URL}/reservation`, {
        reservationRequest: { ...reservation, type: 'FULL_RESERVATION' },
        orderEntries: [],
        depositAmount,
      })
      closeModal()
      updateInstances()
      enqueueSnackbar(t('reservations:ReservationCreatedSuccessfully'), {
        variant: 'success',
      })
    } catch (error) {
      setError(error)
    }
  }

  const transformEscapeRoomArray = (escapeRooms) => {
    return escapeRooms.map((escapeRoom) => ({
      ...escapeRoom.room,
      roomAssignments: escapeRoom.roomAssignments,
    }))
  }

  const loadEscapeRoomsInfo = async () => {
    setLoadingEscapeRooms(true)
    try {
      const { data } = await axios.get(`${API_URL}/room/tenant/with-assignments/${activeTenant}`)

      const escapeRooms = transformEscapeRoomArray(data)

      dispatch({
        type: 'LOAD_INITIAL_ESCAPE_ROOMS',
        payload: {
          escapeRooms,
          activeEscapeRooms: localStorage.getItem(`activeEscapeRoomsIds-${activeTenant}`)
            ? JSON.parse(localStorage.getItem(`activeEscapeRoomsIds-${activeTenant}`))
            : escapeRooms.map((escapeRoom) => escapeRoom.id),
        },
      })
    } catch (error) {
      setError(error)
      dispatch({
        type: 'LOAD_ESCAPE_ROOMS_ERROR',
      })
    }
  }

  const setActiveRooms = (escapeRooms, noRooms = false) => {
    if (escapeRooms.length) {
      dispatch({
        type: 'SET_ACTIVE_ROOMS',
        payload: { escapeRooms, tenantId: activeTenant },
      })
    }
    if (noRooms) {
      dispatch({
        type: 'SET_ACTIVE_ROOMS',
        payload: { escapeRooms, tenantId: activeTenant },
      })
    }
  }

  const setLoadingEscapeRooms = (boolean) => {
    dispatch({
      type: 'SET_LOADING_ESCAPE_ROOMS',
      payload: boolean,
    })
  }

  const editEscapeRoom = async (escapeRoom) => {
    try {
      await axios.put(`${API_URL}/room`, escapeRoom)
      const { data } = await axios.get(`${API_URL}/room/tenant/with-assignments/${activeTenant}`)

      const escapeRooms = transformEscapeRoomArray(data)

      dispatch({
        type: 'LOAD_ESCAPE_ROOMS',
        payload: escapeRooms,
      })

      setActiveRooms(
        state.activeEscapeRoomsIds.map((escapeRoomId) =>
          escapeRooms.find((escapeRoom) => escapeRoom.id === escapeRoomId)
        )
      )
    } catch (error) {
      setError(error)
    }
  }

  const createEscapeRoom = async (escapeRoom) => {
    try {
      await axios.post(`${API_URL}/room`, escapeRoom)
      const { data } = await axios.get(`${API_URL}/room/tenant/with-assignments/${activeTenant}`)

      const escapeRooms = transformEscapeRoomArray(data)

      dispatch({
        type: 'LOAD_ESCAPE_ROOMS',
        payload: escapeRooms,
      })

      escapeRooms.length === 1 && setActiveRooms(escapeRooms)
    } catch (error) {
      setError(error)
    }
  }

  const editRoomAssignments = async (employees) => {
    try {
      await axios.put(`${API_URL}/room/assignment`, employees)
      const { data } = await axios.get(`${API_URL}/room/tenant/with-assignments/${activeTenant}`)

      const escapeRooms = transformEscapeRoomArray(data)

      dispatch({
        type: 'LOAD_ESCAPE_ROOMS',
        payload: escapeRooms,
      })
    } catch (error) {
      setError(error)
    }
  }

  const addRoomAssignments = async (employees) => {
    try {
      await axios.post(`${API_URL}/room/assignment`, employees)
      const { data } = await axios.get(`${API_URL}/room/tenant/with-assignments/${activeTenant}`)

      const escapeRooms = transformEscapeRoomArray(data)

      dispatch({
        type: 'LOAD_ESCAPE_ROOMS',
        payload: escapeRooms,
      })
    } catch (error) {
      setError(error)
    }
  }

  const deleteRoomAssignments = async (roomId, employees) => {
    const userIds = queryParamAsArray('userIds', employees)
    try {
      await axios.delete(`${API_URL}/room/assignment?roomId=${roomId}&${userIds}`)
      const { data } = await axios.get(`${API_URL}/room/tenant/with-assignments/${activeTenant}`)

      const escapeRooms = transformEscapeRoomArray(data)

      dispatch({
        type: 'LOAD_ESCAPE_ROOMS',
        payload: escapeRooms,
      })
    } catch (error) {
      setError(error)
    }
  }

  const deleteEscapeRoom = async (roomId) => {
    try {
      await axios.delete(`${API_URL}/room/${roomId}`)
      const { data } = await axios.get(`${API_URL}/room/tenant/with-assignments/${activeTenant}`)

      const escapeRooms = transformEscapeRoomArray(data)

      escapeRooms.length
        ? setActiveRooms(
            state.activeEscapeRoomsIds
              .filter((escapeRoomId) => escapeRoomId !== roomId)
              .map((escapeRoomId) => escapeRooms.find((escapeRoom) => escapeRoom.id === escapeRoomId)),
            true
          )
        : setActiveRooms([], true)

      dispatch({
        type: 'LOAD_ESCAPE_ROOMS',
        payload: escapeRooms,
      })
    } catch (error) {
      setError(error)
    }
  }

  const removeAssigneeFromReservation = async ({ roomId, reservationId, userId, setIsUpdated }) => {
    try {
      await axios.delete(`${API_URL}/reservation/assignment/${roomId}/${reservationId}/${userId}`)
      setIsUpdated(true)
    } catch (error) {
      setError(error)
    }
  }

  const addAssigneeToReservation = async ({ reservationId, roomId, userId, setIsUpdated }) => {
    try {
      await axios.post(`${API_URL}/reservation/assignment`, [
        {
          reservationId,
          roomId,
          tenantId: activeTenant,
          userId,
        },
      ])
      setIsUpdated(true)
    } catch (error) {
      setError(error)
    }
  }

  const removeAssigneeFromCalendarDay = async ({ roomId, assignmentDate, userId, setIsUpdated }) => {
    try {
      await axios.delete(`${API_URL}/calendar/assignment/${roomId}/${assignmentDate}/${userId}`)
      setIsUpdated(true)
    } catch (error) {
      setError(error)
    }
  }

  const addAssigneeToCalendarDay = async ({ assignmentDate, roomId, userId, setIsUpdated }) => {
    try {
      await axios.post(`${API_URL}/calendar/assignment`, {
        assignmentDate,
        roomId,
        tenantId: activeTenant,
        userId,
      })
      setIsUpdated(true)
    } catch (error) {
      setError(error)
    }
  }

  const changeReservationStatus = async ({
    reservationId,
    roomId,
    status = null,
    amount = null,
    playerCount = null,
    discountCode = null,
    paymentStatus = null,
    paymentMethod = null,
    setIsUpdated = () => {},
  }) => {
    if (status === null && paymentStatus === null) {
      return
    }

    try {
      const { data } = await axios.put(
        `${API_URL}/reservation/status/${roomId}/${reservationId}?${status !== null ? `newStatus=${status}` : ''}${
          paymentStatus !== null ? `${status !== null ? '&' : ''}paymentStatus=${paymentStatus}` : ''
        }${discountCode !== null ? `&discountCode=${discountCode}` : ''}${
          playerCount !== null ? `&playerCount=${playerCount}` : ''
        }${amount !== null ? `&amount=${amount}` : ''}${
          paymentMethod !== null ? `&paymentMethod=${paymentMethod}` : ''
        }`
      )
      setIsUpdated(true)
      return data
    } catch (error) {
      setError(error)
    }
  }

  return (
    <EscapeRoomsContext.Provider
      value={{
        escapeRooms: state.escapeRooms.sort(function (a, b) {
          const nameA = a.name.toUpperCase()
          const nameB = b.name.toUpperCase()
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }

          return 0
        }),
        activeEscapeRooms: state.activeEscapeRooms,
        activeEscapeRoomsIds: state.activeEscapeRoomsIds,
        loadingEscapeRooms: state.loadingEscapeRooms,
        editEscapeRoom,
        setActiveRooms,
        deleteEscapeRoom,
        createEscapeRoom,
        updateReservation,
        createReservation,
        deleteReservation,
        addRoomAssignments,
        editRoomAssignments,
        setLoadingEscapeRooms,
        deleteRoomAssignments,
        changeReservationStatus,
        addAssigneeToReservation,
        addAssigneeToCalendarDay,
        removeAssigneeFromReservation,
        removeAssigneeFromCalendarDay,
      }}
      displayName="Escape rooms"
    >
      {children}
    </EscapeRoomsContext.Provider>
  )
}

const useEscapeRooms = () => {
  const context = useContext(EscapeRoomsContext)

  if (context === undefined) {
    throw new Error('useEscapeRooms can only be used inside EscapeRoomsProvider')
  }

  return context
}

export { EscapeRoomsProvider, useEscapeRooms }
