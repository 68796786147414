import { MenuItem, TextField, Typography } from '@mui/material'
import { EMAIL_TYPES } from 'config'
import { useTranslation } from 'react-i18next'

export default function EmailTemplateSelect({ emailTemplateId, setEmailTemplateId, filteredEmailTemplates }) {
  const { t } = useTranslation('reservations')

  return (
    <>
      <Typography variant="overline">{t('EmailTemplateToSend')}</Typography>
      <TextField
        label={t('emailTemplates:EmailTemplate')}
        fullWidth
        size="small"
        error={!emailTemplateId}
        select
        value={emailTemplateId}
        onChange={(e) => setEmailTemplateId(e.target.value)}
        helperText={!!emailTemplateId ? '' : t('MissingEmailTemplateDisclaimer')}
      >
        {filteredEmailTemplates.map((template) => (
          <MenuItem key={template.id} value={template.id}>
            {template?.assignments?.[0]?.roomId === null
              ? t('emailTemplates:TenantDefaultTemplate', {
                  category: t(`emailTemplates:${EMAIL_TYPES[template?.assignments?.[0]?.emailType]?.value}`),
                })
              : template.name}{' '}
            ({t(`emailTemplates:${EMAIL_TYPES[template?.assignments?.[0]?.emailType]?.value}`)})
          </MenuItem>
        ))}
      </TextField>
    </>
  )
}
