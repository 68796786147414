import { capitalize, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function DynamicFieldsTextFields({
  emailTemplateId,
  setEmailDynamicValues,
  emailDynamicValues,
  filteredEmailTemplates,
}) {
  const { t } = useTranslation('reservations')

  const updateDynamicValues = (value, key) => {
    setEmailDynamicValues((state) => ({ ...state, [key.slice(1, -1)]: value }))
  }

  const selectedTemplate = filteredEmailTemplates.find((template) => template.id === emailTemplateId)

  return (
    selectedTemplate?.dynamicValues &&
    Object.keys(selectedTemplate?.dynamicValues).length > 0 && (
      <>
        <Typography variant="overline">{t('DynamicValues')}</Typography>
        {Object.values(selectedTemplate?.dynamicValues).map((dv) => {
          return (
            <TextField
              multiline
              maxRows={3}
              size="small"
              key={dv.value}
              label={capitalize(dv.value.split('{DYNAMIC_')[1]?.split('}')[0].replaceAll('_', ' ').toLowerCase())}
              value={emailDynamicValues[dv.value]}
              onChange={(e) => updateDynamicValues(e.target.value, dv.value)}
            />
          )
        })}
      </>
    )
  )
}
