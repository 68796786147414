import { History } from '@mui/icons-material'
import { List, ListItem, ListItemText } from '@mui/material'
import { ButtonTriggeredDialog } from 'components'
import { EMAIL_TYPES } from 'config'
import { useTenantInfo } from 'core'
import { TablePagination } from 'layouts/Main/components/Table/components'
import { useTranslation } from 'react-i18next'

export default function SentEmailsHistory({
  history,
  hasNext,
  setAsc,
  setCountClicks,
  setFrom,
  setPageSize,
  startNextOrPreviousPageFrom,
  countClicks,
  asc,
  from,
  pageSize,
  isLoading,
}) {
  const { t } = useTranslation('emailTemplates')
  const { emailTemplates } = useTenantInfo()

  return (
    <ButtonTriggeredDialog
      closeIcon
      hideActions
      buttonText={`${t('SentEmailsHistory')} (${history.data.length})`}
      icon={History}
    >
      <List sx={{ pt: 0 }}>
        {history.data.map((email) => {
          const emailTemplate = emailTemplates.find((template) => template.id === email.metadata.emailTemplateId)
          emailTemplates.find((template) => template.id === email.metadata.emailTemplateId)

          return (
            <ListItem key={email.id} disableGutters>
              <ListItemText
                primary={
                  emailTemplate
                    ? `${
                        emailTemplate?.assignments?.[0]?.roomId === null
                          ? t('emailTemplates:TenantDefaultTemplate', {
                              category: t(
                                `emailTemplates:${EMAIL_TYPES[emailTemplate?.assignments?.[0]?.emailType].value}`
                              ),
                            })
                          : emailTemplate?.name
                      } (${t(EMAIL_TYPES[emailTemplate?.assignments?.[0]?.emailType]?.value)})`
                    : t('UnknownEmailTemplate')
                }
                secondary={
                  <>
                    {t('SentTo')} {email.metadata.recipients.join(', ')}
                  </>
                }
              />
            </ListItem>
          )
        })}
      </List>
      <TablePagination
        removeButtons={true}
        setFrom={setFrom}
        asc={asc}
        setAsc={setAsc}
        from={from}
        countClicks={countClicks}
        setCountClicks={setCountClicks}
        hasNext={hasNext}
        startNextOrPreviousPageFrom={startNextOrPreviousPageFrom}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalProducts={history?.total}
        itemsOnCurrentPage={history?.data?.length}
        showRowsPerPage={false}
      />
    </ButtonTriggeredDialog>
  )
}
