import React from 'react'
import { Button, Typography, Box, TextField } from '@mui/material'
import { PageLayout } from '..'
import axios from 'axios'
import { API_URL } from 'config'
import i18n from 'translations'
import { getCookie, parseJwt } from 'helpers'
import { AUTH_TOKEN } from 'core/contexts/AuthContext/AuthContext'
import * as Sentry from '@sentry/react'
import { NotificationType, sendEmbed } from 'utils/discord'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      errorDescription: '',
    }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    const sendError = async (error) => {
      try {
        await axios.post(`${API_URL}/error-report`, { location: window.location.href, data: error })
        sendEmbed(NotificationType.SYSTEM_ALERT, {
          message: error.message,
          username: parseJwt(getCookie(AUTH_TOKEN))?.upn,
        })
        Sentry.captureException(error, { extra: errorInfo })
      } catch (err) {
        console.error('Could not send error:', err)
      }
    }

    sendError({ error, errorInfo })
  }

  handleChange = (event) => {
    this.setState({ errorDescription: event.target.value })
  }

  handleSubmit = async () => {
    const { errorDescription } = this.state
    if (errorDescription) {
      await sendEmbed(NotificationType.SYSTEM_ALERT_FEEDBACK, {
        message: errorDescription,
        username: parseJwt(getCookie(AUTH_TOKEN))?.upn,
      })
      window.location.reload()
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <PageLayout maxWidth="sm">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: (theme) => `${theme.sizing.header.height}px` }}
          >
            <img src="/illustrations/issue.svg" alt="error" style={{ maxWidth: 550, width: '100%', height: 'auto' }} />
            <Typography variant="h3" align="center" sx={{ mt: 4 }}>
              {i18n.t('InternalIssue')}
            </Typography>
            <Typography>{i18n.t('InternalIssueDesc')}</Typography>

            <TextField
              label={i18n.t('DescribeTheError')}
              variant="outlined"
              value={this.state.errorDescription}
              onChange={this.handleChange}
              sx={{ mt: 2 }}
              maxLength={1023}
              multiline
              minRows={3}
              fullWidth
            />

            <Box display="flex" sx={{ mt: 1, width: '100%' }} justifyContent="end">
              <Button onClick={() => window.location.reload()} variant="outlined">
                {i18n.t('TryAgain')}
              </Button>
              <Button
                onClick={this.handleSubmit}
                variant="outlined"
                color="primary"
                sx={{ ml: 1 }}
                disabled={!!!this.state.errorDescription}
              >
                {i18n.t('SendErrorReport')}
              </Button>
            </Box>
          </Box>
        </PageLayout>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
