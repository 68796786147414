import { MenuItem, ListItemIcon, ListItemText, Button, SvgIcon } from '@mui/material'
import { ButtonTriggeredMenu } from 'components'
import { AT_VENUE_PAYMENT_STATUSES } from 'config'
import { ONLINE_PAYMENT_STATUSES } from 'config'
import { PAYMENT_STATUSES } from 'config'
import { useEscapeRooms, useTenantInfo } from 'core'
import { useTranslation } from 'react-i18next'
import { isOnlinePaymentActivatedForRoom } from 'utils'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import { useState } from 'react'
import { SetAmountDialog, UnpaidDialog } from './components'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { RESERVATION_STATUSES } from 'config'

export default function PaymentStatus({
  reservation,
  handleCloseMainMenu,
  isCalendarView = true,
  size = 'small',
  setIsUpdated = () => {},
}) {
  const { t } = useTranslation('reservations')
  const { escapeRooms } = useEscapeRooms()
  const { isStripeActivated } = useTenantInfo()
  const escapeRoom = escapeRooms.find((room) => room.id === reservation.key.roomId)

  const [amountDialogProps, setAmountDialogProps] = useState({ type: null, open: false, handleClose: () => {} })
  const [unpaidDialogProps, setUnpaidDialogProps] = useState({ open: false, handleClose: () => {} })

  const handleSelectStatus = async ({ paymentStatus, handleClose }) => {
    if (paymentStatus === PAYMENT_STATUSES.NOT_PAID.key) {
      setUnpaidDialogProps({ open: true, handleClose })
    } else if (
      paymentStatus === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key ||
      paymentStatus === PAYMENT_STATUSES.PAID_AT_VENUE.key
    ) {
      setAmountDialogProps({ type: paymentStatus, open: true, handleClose })
    }
  }

  const getMenuItems = ({ isOnlinePayment, handleClose }) => {
    const statusList = isOnlinePayment
      ? [...ONLINE_PAYMENT_STATUSES, ...AT_VENUE_PAYMENT_STATUSES]
      : AT_VENUE_PAYMENT_STATUSES

    return statusList.map(({ key, value, icon }) => {
      const isDisabled =
        reservation.paymentStatus === key ||
        key === PAYMENT_STATUSES.ONLINE_PAYMENT_PENDING.key ||
        key === PAYMENT_STATUSES.PARTIALLY_PAID_ONLINE.key ||
        key === PAYMENT_STATUSES.PAID_ONLINE.key

      return (
        <MenuItem
          key={key}
          disabled={isDisabled}
          onClick={() => handleSelectStatus({ paymentStatus: key, handleClose })}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small" component={icon} />
          </ListItemIcon>
          <ListItemText>{t(value)}</ListItemText>
        </MenuItem>
      )
    })
  }

  return (
    <>
      <ButtonTriggeredMenu
        buttonComponent={isCalendarView ? MenuItem : Button}
        additionalOnClose={handleCloseMainMenu}
        menuProps={{
          anchorOrigin: {
            vertical: isCalendarView ? 'top' : 'bottom',
            horizontal: isCalendarView ? 'right' : 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        buttonText={
          isCalendarView ? (
            <>
              <ListItemIcon>
                <AttachMoneyOutlinedIcon fontSize={size} />
              </ListItemIcon>
              <ListItemText>{t('ChangePaymentStatus')}</ListItemText>
            </>
          ) : (
            t(PAYMENT_STATUSES[reservation.paymentStatus].value)
          )
        }
        disabled={reservation.status === RESERVATION_STATUSES['BLOCKED'].key}
        size={size}
        color="inherit"
        {...(!isCalendarView && {
          startIcon: <SvgIcon component={PAYMENT_STATUSES[reservation.paymentStatus].icon} />,
          endIcon: <ArrowDropDownIcon />,
        })}
      >
        {({ handleClose }) =>
          getMenuItems({
            isOnlinePayment: isOnlinePaymentActivatedForRoom(escapeRoom) && isStripeActivated,
            handleClose,
          })
        }
      </ButtonTriggeredMenu>
      {amountDialogProps.open && (
        <SetAmountDialog
          dialogProps={amountDialogProps}
          handleCloseMainMenu={handleCloseMainMenu}
          handleCloseDialog={() => {
            amountDialogProps.handleClose()
            setAmountDialogProps({ type: null, open: false, handleClose: () => {} })
          }}
          reservation={reservation}
          isCalendarView={isCalendarView}
          escapeRoom={escapeRoom}
          setIsUpdated={setIsUpdated}
        />
      )}
      {unpaidDialogProps.open && (
        <UnpaidDialog
          dialogProps={unpaidDialogProps}
          handleCloseMainMenu={handleCloseMainMenu}
          handleCloseDialog={() => {
            unpaidDialogProps.handleClose()
            setUnpaidDialogProps({ open: false, handleClose: () => {} })
          }}
          reservation={reservation}
          escapeRoom={escapeRoom}
          isCalendarView={isCalendarView}
          setIsUpdated={setIsUpdated}
        />
      )}
    </>
  )
}
