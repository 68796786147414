import { Add } from '@mui/icons-material'
import { Box, Chip, TextField, Typography } from '@mui/material'
import { ButtonTriggeredDialog } from 'components'
import { isValidEmail } from 'helpers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function EmailRecipients({ emails, setEmails }) {
  const { t } = useTranslation('reservations')

  const [emailToAdd, setEmailToAdd] = useState('')

  return (
    <>
      <Typography variant="overline">{t('EmailRecipients')}</Typography>
      <Box display="flex" flexWrap="wrap" gap={0.5} mb={1.5}>
        {emails.map((email) => (
          <Chip label={email} key={email} onDelete={() => setEmails(emails.filter((_email) => _email !== email))} />
        ))}
        <ButtonTriggeredDialog
          buttonComponent={Chip}
          buttonComponentProps={{
            label: t('translation:AddNewEmail'),
            icon: <Add />,
          }}
          actionButtonProps={{ disabled: !isValidEmail(emailToAdd) || emails.includes(emailToAdd) }}
          handleClick={() => {
            if (isValidEmail(emailToAdd) && !emails.includes(emailToAdd)) {
              setEmails((state) => [...state, emailToAdd])
              setEmailToAdd('')
            }
          }}
        >
          <TextField
            label={t('Email')}
            type="email"
            required
            fullWidth
            autoFocus
            value={emailToAdd}
            onChange={(e) => setEmailToAdd(e.target.value)}
          />
        </ButtonTriggeredDialog>
      </Box>
    </>
  )
}
