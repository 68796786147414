import { useState } from 'react'
import { Box, Grid, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Chat, NavBar, Pocket, TopBar } from './components'
import { useChat, useUserPreferences } from 'core'
import ErrorBoundary from './components/ErrorBoundary'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: '100dvh',
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
}))

export const CONTENT_PADDING_BOTTOM = '48px'

const MainLayout = ({ children }) => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)
  const { isDrawerOpen, isDesktop } = useUserPreferences()
  const { pinChat } = useChat()
  const theme = useTheme()

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item>
          <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
        </Grid>
        <Grid item>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
        </Grid>
      </Grid>
      <div
        className={classes.wrapper}
        style={
          isDesktop
            ? isDrawerOpen
              ? { paddingLeft: theme.sizing.sidebar.width }
              : { paddingLeft: theme.sizing.sidebar.collapsedWidth }
            : {}
        }
      >
        <div className={classes.contentContainer}>
          <Box
            sx={{
              flex: '1 1 auto',
              height: '100%',
              overflow: 'auto',
              pb: CONTENT_PADDING_BOTTOM,
              pt: `${theme.sizing.header.height}px`,
            }}
            id="content"
          >
            <ErrorBoundary>{children}</ErrorBoundary>
          </Box>
          {pinChat && <Chat />}
          <Pocket />
        </div>
      </div>
    </div>
  )
}

export default MainLayout
