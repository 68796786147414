import { Add, ExpandMore, ImageNotSupportedOutlined, Remove } from '@mui/icons-material'
import { Box, CardMedia, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material'
import DisplayCurrency from 'components/DisplayCurrency'
import { SERVICES_URL } from 'config'
import { useTenantInfo } from 'core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function Product({ product, initialCart, cart, setCart, disableRemoveButton, disableAddButton }) {
  const { t } = useTranslation()
  const { activeTenantFullInfo } = useTenantInfo()

  const isMainProduct = product?.productVariants?.length > 0

  const [openCollapse, setOpenCollapse] = useState(
    isMainProduct && product.productVariants.some((p) => Object.keys(cart).includes(p.id))
  )

  const addToCart = (productId) => {
    setCart((products) => ({ ...products, [productId]: productId in products ? products[productId] + 1 : 1 }))
  }

  const removeFromCart = (productId) => {
    setCart((products) => ({
      ...products,
      [productId]: !(productId in products) || products[productId] === 0 ? 0 : products[productId] - 1,
    }))
  }

  const isAddButtonDisabled = (productId) => {
    if (!(productId in initialCart)) {
      return true
    }

    if (initialCart[productId] <= cart[productId]) {
      return true
    }

    return false
  }

  return (
    <Stack
      sx={{
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        p: 1,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box display="flex" alignItems="center" gap={1}>
          {!!product.images.length ? (
            <CardMedia
              component="img"
              height="50"
              alt={product.name}
              sx={{ borderRadius: 1, overflow: 'hidden', maxWidth: 50 }}
              image={`${SERVICES_URL}/media/${product.images[0]}?q=100&h=50`}
            />
          ) : (
            <Box sx={{ height: 50, width: 50, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ImageNotSupportedOutlined fontSize="large" />
            </Box>
          )}
          <Box display="flex" flexDirection="column">
            <Typography
              noWrap
              sx={{ maxWidth: 200 }}
              title={`${product.name}${isMainProduct ? '' : ` (${product.price} ${activeTenantFullInfo.currency})`}`}
            >
              {product.name}{' '}
              {!isMainProduct && (
                <>
                  (<DisplayCurrency number={product.price} />)
                </>
              )}
            </Typography>

            <Box display="flex" sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
              {product.config.quantityEnabled && !isMainProduct && (
                <Typography
                  variant="caption"
                  color={product.quantity <= 3 ? 'error' : product.quantity <= 10 ? 'warning.main' : 'textSecondary'}
                  sx={{ mr: 1 }}
                >
                  {t('products:Quantity')}: {product.quantity}
                </Typography>
              )}
              <Stack>
                {product.config.quantityEnabled && cart?.[product.id] >= product.quantity && !isMainProduct && (
                  <Typography variant="caption" sx={{ color: 'warning.main' }}>
                    {t('products:ReachedAvailableQuantity')}
                  </Typography>
                )}
                {product.config.maxOrderQuantityEnabled &&
                  cart[product.id] >= product.maxOrderQuantity &&
                  !isMainProduct && (
                    <Typography variant="caption" sx={{ color: 'warning.main' }}>
                      {t('products:ReachedMaxOrderQuantity')}
                    </Typography>
                  )}
              </Stack>
            </Box>
          </Box>
        </Box>

        {isMainProduct ? (
          <>
            <IconButton onClick={() => setOpenCollapse((state) => !state)} size="small">
              <ExpandMore
                sx={{
                  transform: `rotate(${openCollapse ? '180deg' : '0deg'})`,
                  transition: 'transform 0.25s',
                }}
              />
            </IconButton>
          </>
        ) : (
          <Box display="flex" alignItems="center">
            <IconButton
              size="small"
              disabled={disableRemoveButton || !(product.id in cart) || cart?.[product.id] === 0}
              onClick={() => removeFromCart(product.id)}
            >
              <Remove />
            </IconButton>
            <Typography align="center" sx={{ mx: 1, cursor: 'default', width: 16 }}>
              {product.id in cart ? cart[product.id] : 0}
            </Typography>
            <IconButton
              size="small"
              disabled={
                disableAddButton
                  ? isAddButtonDisabled(product.id)
                  : false ||
                    (product.config.quantityEnabled && product.quantity === 0) ||
                    (product.config.quantityEnabled && cart[product.id] >= product.quantity) ||
                    (product.config.maxOrderQuantityEnabled && cart[product.id] >= product.maxOrderQuantity)
              }
              onClick={() => addToCart(product.id)}
            >
              <Add />
            </IconButton>
          </Box>
        )}
      </Box>
      <Collapse
        in={openCollapse}
        sx={{
          '&.MuiCollapse-entered': { width: '100% !important' },
          '& .MuiCollapse-wrapperInner': { width: '100% !important' },
        }}
      >
        <Divider sx={{ my: 1 }} />
        <Stack gap={0.5}>
          {product.productVariants.map((productVariant) => (
            <Box display="flex" alignItems="center" justifyContent="space-between" key={productVariant.id}>
              <Box display="flex" alignItems="center" gap={1} flexGrow={1}>
                {!!productVariant.images.length ? (
                  <CardMedia
                    component="img"
                    height="64"
                    alt={productVariant.name}
                    sx={{ borderRadius: 1, overflow: 'hidden', maxWidth: 64 }}
                    image={`${SERVICES_URL}/media/${productVariant.images[0]}?q=100&h=64`}
                  />
                ) : null}
                <Box>
                  <Typography sx={{ fontSize: '16px', lineHeight: 1.2 }}>
                    {productVariant.name} (
                    <DisplayCurrency number={productVariant.price} />)
                  </Typography>
                  <Box display="flex" sx={{ flexWrap: { xs: 'wrap', md: 'nowrap' } }}>
                    {productVariant.config.quantityEnabled && (
                      <Typography
                        variant="caption"
                        color={
                          productVariant.quantity <= 3
                            ? 'error'
                            : productVariant.quantity <= 10
                            ? 'warning.main'
                            : 'textSecondary'
                        }
                        sx={{ mr: 1 }}
                      >
                        {t('products:Quantity')}: {productVariant.quantity}
                      </Typography>
                    )}
                    <Stack>
                      {productVariant.config.quantityEnabled && cart?.[productVariant.id] >= productVariant.quantity && (
                        <Typography variant="caption" sx={{ color: 'warning.main' }}>
                          {t('products:ReachedAvailableQuantity')}
                        </Typography>
                      )}
                      {productVariant.config.maxOrderQuantityEnabled &&
                        cart[productVariant.id] >= productVariant.maxOrderQuantity && (
                          <Typography variant="caption" sx={{ color: 'warning.main' }}>
                            {t('products:ReachedMaxOrderQuantity')}
                          </Typography>
                        )}
                    </Stack>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <IconButton
                  size="small"
                  disabled={disableRemoveButton || !(productVariant.id in cart) || cart?.[productVariant.id] === 0}
                  onClick={() => removeFromCart(productVariant.id)}
                >
                  <Remove />
                </IconButton>
                <Typography align="center" sx={{ mx: 1, cursor: 'default', width: 16 }}>
                  {productVariant.id in cart ? cart[productVariant.id] : 0}
                </Typography>
                <IconButton
                  size="small"
                  disabled={
                    disableAddButton
                      ? isAddButtonDisabled(productVariant.id)
                      : false ||
                        (productVariant.config.quantityEnabled && productVariant.quantity === 0) ||
                        (productVariant.config.quantityEnabled && cart[productVariant.id] >= productVariant.quantity) ||
                        (productVariant.config.maxOrderQuantityEnabled &&
                          cart[productVariant.id] >= productVariant.maxOrderQuantity)
                  }
                  onClick={() => addToCart(productVariant.id)}
                >
                  <Add />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}
