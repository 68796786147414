import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material'

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort, columns } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.field}
            align={column.align}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy.toString() === column.field.toString() ? order : false}
          >
            {column.disableSorting ? (
              column.name
            ) : (
              <TableSortLabel
                active={orderBy.toString() === column.field.toString()}
                direction={orderBy.toString() === column.field.toString() ? order : 'asc'}
                onClick={createSortHandler(column.field)}
              >
                {column.name}
                {orderBy.toString() === column.field.toString() ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
