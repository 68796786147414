import DisplayValue from 'layouts/Main/components/TableDisplayValues/DisplayValue'
import { getObjectValueFromArray } from '../../functions'
import { Link, TableCell } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'

export default function TableRowContent({ column, row, mutate = () => {} }) {
  const content = (
    <DisplayValue
      row={row}
      column={column}
      additionalFieldValue={column.additionalField && getObjectValueFromArray(column.additionalField, row)}
      setIsUpdated={() => mutate()}
    >
      {!column.json
        ? getObjectValueFromArray(column.field, row)
        : getObjectValueFromArray(column.field, JSON.parse(row[column.json]))}
    </DisplayValue>
  )

  const link = typeof column.link === 'function' ? column.link(row) : column.link

  return (
    <TableCell align={column.align} sx={{ maxWidth: 300 }}>
      {column.link ? (
        <Link component={RouterLink} to={link}>
          {content}
        </Link>
      ) : (
        content
      )}
    </TableCell>
  )
}
