import { alpha } from 'utils'

/* eslint-disable import/no-anonymous-default-export */
export default (theme) => ({
  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: alpha(theme.palette.grey[800], 0.48),
      },
      invisible: {
        background: 'transparent',
      },
    },
  },
})
