import clsx from 'clsx'
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Menu,
  Typography,
  Divider,
  ListItemText,
  ListItemButton,
  List,
  Button,
  useScrollTrigger,
} from '@mui/material'
import { useTheme, makeStyles } from '@mui/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { alpha } from 'utils'
import { useAuth, useTenantInfo, useUserPreferences, useMisc } from 'core'
import { useTranslation } from 'react-i18next'
import {
  Language,
  OnlineUsers,
  //  Notifications,
  SelectRoomToModerate,
} from './components'
import { useState } from 'react'
import { Logout, Settings, Person, Devices } from '@mui/icons-material'
import { API_URL } from 'config'
import { useNavigate } from 'react-router-dom'
import { PageURLs } from 'Routes'
import { KeyhowlLogo, ProfileAvatar } from 'components'
import { SERVICES_URL } from 'config'

const useStyles = makeStyles((theme) => ({
  root: {
    background: alpha(theme.palette.background.main, 0.8),
    height: theme.sizing.header.height,
    color: theme.palette.text.primary,
    justifyContent: 'center',
    backdropFilter: 'blur(6px)',
    transition: 'all 0.12s',
  },
  collapsedHeight: {
    transition: 'all 0.12s',
    height: theme.sizing.header.collapsedHeight,
  },
  avatar: {
    cursor: 'pointer',
    width: 40,
    height: 40,
    marginLeft: theme.spacing(1),
  },
}))

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles()
  const theme = useTheme()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { t } = useTranslation()
  const { user } = useAuth()
  const { activeTenantFullInfo } = useTenantInfo()
  const { isDrawerOpen, isDesktop } = useUserPreferences()
  const { topBarComponent } = useMisc()

  const triggerForTopBar = useScrollTrigger({
    disableHysteresis: true,
    threshold: 40,
    target: document.querySelector('#content') ?? window,
  })

  const triggerForTopBarComponent = useScrollTrigger({
    disableHysteresis: true,
    threshold: 110,
    target: document.querySelector('#content') ?? window,
  })

  const [anchorEl, setAnchorEl] = useState(null)
  const [navigateTo, setNavigateTo] = useState(null)

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <AppBar
      color="transparent"
      className={clsx(triggerForTopBar && classes.collapsedHeight, classes.root, className)}
      elevation={0}
      position="fixed"
      style={
        isDesktop
          ? isDrawerOpen
            ? { paddingLeft: theme.sizing.sidebar.width }
            : { paddingLeft: theme.sizing.sidebar.collapsedWidth }
          : {}
      }
      {...rest}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Box display="flex">
          <Box sx={{ display: { lg: 'none', xs: 'flex' }, mr: 1 }}>
            <Box display="flex" justifyContent="center" alignItems="center">
              {!activeTenantFullInfo?.logo ? (
                <KeyhowlLogo />
              ) : (
                <img
                  src={`${SERVICES_URL}/media/${activeTenantFullInfo?.logo}?q=100&h=40`}
                  alt="logo"
                  width="40"
                  height="40"
                  style={{ width: 'auto', height: 40 }}
                />
              )}
            </Box>
          </Box>
          <SelectRoomToModerate />
        </Box>
        <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
          {topBarComponent && (
            <Box
              sx={triggerForTopBarComponent ? { display: 'flex', alignItems: 'center', mr: 2 } : { display: 'none' }}
            >
              {topBarComponent}
              <Divider flexItem variant="middle" orientation="vertical" sx={{ ml: 2 }} />
            </Box>
          )}
          <Language />
          <OnlineUsers />
          {/* <Notifications /> */}
          <ProfileAvatar
            className={classes.avatar}
            firstName={user?.firstName}
            lastName={user?.lastName}
            src={user?.avatar && `${API_URL}/media/${user?.avatar}`}
            onClick={handleClick}
          />
        </Box>
        <Box sx={{ display: { lg: 'none', xs: 'flex' } }}>
          <IconButton color="primary" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionProps={{
          onExited: () => {
            if (navigateTo) {
              navigate(navigateTo)
              setNavigateTo(null)
            }
          },
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 2,
              boxShadow: theme.customShadows.z16,
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
              minWidth: 220,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box mb={1.25} px={2} py={0.75}>
          <Typography variant="subtitle1">
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {user?.username}
          </Typography>
        </Box>
        <Divider />
        <List>
          <ListItemButton
            onClick={() => {
              handleClose()
              setNavigateTo(PageURLs.Profile)
            }}
          >
            <Person sx={{ mr: 1.75, color: 'text.secondary' }} />
            <ListItemText>{t('Profile')}</ListItemText>
          </ListItemButton>
          {/* <ListItemButton onClick={() => {
              handleClose()
              setNavigateTo(PageURLs.Profile)
            }} component={RouterLink} to={PageURLs.Billing}>
            <Receipt sx={{ mr: 1.75, color: 'text.secondary' }} />
            <ListItemText>{t('Billing')}</ListItemText>
          </ListItemButton> */}
          <ListItemButton
            onClick={() => {
              handleClose()
              setNavigateTo(PageURLs.ActiveSessions)
            }}
          >
            <Devices sx={{ mr: 1.75, color: 'text.secondary' }} />
            <ListItemText>{t('ActiveSessions')}</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => {
              handleClose()
              setNavigateTo(PageURLs.Preferences)
            }}
          >
            <Settings sx={{ mr: 1.75, color: 'text.secondary' }} />
            <ListItemText>{t('Preferences')}</ListItemText>
          </ListItemButton>
        </List>
        <Box px={2} mb={0.75}>
          <Button
            startIcon={<Logout />}
            variant="outlined"
            fullWidth
            sx={{ color: 'text.primary' }}
            onClick={() => logout({ sessionExpired: false })}
          >
            {t('Logout')}
          </Button>
        </Box>
      </Menu>
    </AppBar>
  )
}

export default TopBar
