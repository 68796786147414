import axios from 'axios'
import { DISCORD_WEBHOOK_URL } from 'config'

export const NotificationType = {
  NEW_USER: 'NEW_USER',
  NEW_PAYMENT: 'NEW_PAYMENT',
  SYSTEM_ALERT: 'SYSTEM_ALERT',
  SYSTEM_ALERT_FEEDBACK: 'SYSTEM_ALERT_FEEDBACK',
}

const getEmbedContent = (type, data) => {
  switch (type) {
    case NotificationType.NEW_USER:
      return {
        title: '🎉 New User Registered!',
        description: `Welcome **${data.username}**!`,
        color: 3066993, // Green
        fields: [
          { name: 'Name', value: `${data.firstName} ${data.lastName}`, inline: true },
          { name: 'Email', value: data.username, inline: true },
        ],
        footer: { text: 'New user registration notification' },
        timestamp: new Date().toISOString(),
      }

    case NotificationType.NEW_PAYMENT:
      return {
        title: '💸 New Payment Received!',
        description: `**${data.username}** has made a payment.`,
        color: 15105570, // Red
        fields: [
          { name: 'Amount', value: `$${data.amount}`, inline: true },
          { name: 'Transaction ID', value: data.transactionId, inline: true },
        ],
        footer: { text: 'Payment notification' },
        timestamp: new Date().toISOString(),
      }

    case NotificationType.SYSTEM_ALERT:
      return {
        title: '⚠️ System Alert!',
        description: data.message || 'No message',
        fields: [
          { name: 'User', value: data.username || 'JWT Parse failed', inline: true },
          { name: 'Page', value: window.location.href, inline: true },
        ],
        color: 16776960, // Yellow
        footer: { text: 'System alert notification' },
        timestamp: new Date().toISOString(),
      }
    case NotificationType.SYSTEM_ALERT_FEEDBACK:
      return {
        title: '⚠️ System Alert Feedback!',
        description: data.message || 'No message',
        fields: [
          { name: 'User', value: data.username || 'JWT Parse failed', inline: true },
          { name: 'Page', value: window.location.href, inline: true },
        ],
        color: 16776960, // Yellow
        footer: { text: 'System alert notification' },
        timestamp: new Date().toISOString(),
      }
    default:
      return null
  }
}

export const sendEmbed = async (type, data) => {
  const embedContent = getEmbedContent(type, data)

  if (!embedContent) {
    console.error('Invalid notification type')
    return
  }

  try {
    await axios.post(DISCORD_WEBHOOK_URL, {
      embeds: [embedContent],
    })
  } catch (error) {
    console.error('Error sending embed:', error)
  }
}
