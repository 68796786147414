import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  TextField,
} from '@mui/material'
import { PAYMENT_STATUSES, RESERVATION_STATUSES } from 'config'
import { useCalendar, useEscapeRooms } from 'core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePricing } from 'utils/hooks'
import useFinanceMethods from 'utils/hooks/useFinanceMethods'
import Loading from 'views/Loading'

const CanceledReservationDialog = ({
  open,
  handleCloseMainMenu,
  handleClose,
  reservation,
  isCalendarView = true,
  setIsUpdated = () => {},
}) => {
  const { t } = useTranslation('reservations')
  const { changeReservationStatus } = useEscapeRooms()
  const { loadCalendar } = useCalendar()
  const { refundFinance } = useFinanceMethods()
  const { priceFromExistingFinances, isFinancesLoading, existingFinances, displayCurrency } = usePricing({
    reservation,
  })

  const [shouldRefund, setShouldRefund] = useState(false)
  const [amount, setAmount] = useState(0)
  const totalSumOfFinancesToDisplay = displayCurrency(priceFromExistingFinances)

  const handleUpdateReservation = async () => {
    handleClose()
    handleCloseMainMenu()
    shouldRefund && (await refundFinance(existingFinances[0], amount))
    const newReservation = await changeReservationStatus({
      reservationId: reservation.key.reservationId,
      roomId: reservation.key.roomId,
      status: RESERVATION_STATUSES.CANCELED.key,
      paymentStatus: shouldRefund ? PAYMENT_STATUSES.NOT_PAID.key : null,
    })
    isCalendarView && (await loadCalendar())
    setIsUpdated(newReservation)
  }

  useEffect(() => {
    if (!isFinancesLoading && priceFromExistingFinances === 0) {
      handleUpdateReservation()
    }
    // eslint-disable-next-line
  }, [isFinancesLoading, priceFromExistingFinances])

  useEffect(() => {
    if (shouldRefund && amount === 0) {
      setAmount(priceFromExistingFinances)
    }
    // eslint-disable-next-line
  }, [shouldRefund])

  return (
    <Dialog open={open && !isFinancesLoading && priceFromExistingFinances !== 0} onClose={handleClose}>
      <DialogTitle>{t('SetStatusToCanceled')}</DialogTitle>
      <DialogContent>
        {isFinancesLoading ? (
          <Loading height="auto" />
        ) : (
          <>
            <DialogContentText>
              {t('ReservationHasExistingFinances', { value: totalSumOfFinancesToDisplay })}
            </DialogContentText>
            <FormControlLabel
              sx={{ mt: 2 }}
              control={<Checkbox checked={shouldRefund} onChange={(e) => setShouldRefund(e.target.checked)} />}
              label={t('RefundFinance')}
            />

            {shouldRefund && (
              <TextField
                sx={{ mt: 1 }}
                label={t('finances:Amount')}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                fullWidth
                type="number"
              />
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>{t('translation:Cancel')}</Button>
        <Button color="primary" onClick={handleUpdateReservation}>
          {t('translation:Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CanceledReservationDialog
