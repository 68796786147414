export const IMAGINARY_RESERVATION_STATUSES = {
  OPEN: {
    key: 'OPEN',
  },
  OPEN_WITHOUT_SELECTED_HOUR: {
    key: 'OPEN_WITHOUT_SELECTED_HOUR',
  },
  DUPLICATE: {
    key: 'DUPLICATE',
  },
}
