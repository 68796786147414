import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { transformLanguage } from 'helpers'

const DateValue = ({ isFullDate, children: value }) => {
  const { i18n } = useTranslation()
  const format = isFullDate ? { dateStyle: 'short', timeStyle: 'short' } : { dateStyle: 'short' }

  return DateTime.fromMillis(value, { zone: 'utc' }).setLocale(transformLanguage(i18n.language)).toLocaleString(format)
}

export default DateValue
