import { Box } from '@mui/material'

export default function CustomBadge({ borderColor, color, children }) {
  return (
    <Box
      sx={(theme) => ({
        borderRadius: 1,
        border: '1px solid',
        fontSize: 11,
        textTransform: 'uppercase',
        px: 0.25,
        py: 0,
        fontWeight: 600,
        height: '16px',
        lineHeight: '13px',
        borderColor: borderColor || theme.palette.text.primary,
        color: color || theme.palette.text.primary,
      })}
    >
      {children}
    </Box>
  )
}
