import { TableCell, Checkbox, TableHead, TableRow, TableSortLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'

const EnhancedTableHead = (props) => {
  const {
    classes,
    order,
    orderBy,
    selected,
    setSelected,
    onRequestSort,
    columns,
    withCheckbox,
    withActions,
    rows = [],
    isLoading,
  } = props
  const { t } = useTranslation()
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  const handleSelectAllClick = (event) => {
    const newSelecteds = rows.map((n) => n.id)
    if (event.target.checked) {
      setSelected([...new Set([...selected, ...newSelecteds])])
      return
    }
    setSelected(selected.filter((e) => !newSelecteds.includes(e)))
  }

  const isSelectAllChecked = () => {
    if (rows.length) {
      const filteredRowsData = rows.map((e) => e.id)

      return filteredRowsData.every((e) => selected.includes(e))
    }
  }

  return (
    <TableHead>
      <TableRow>
        {withCheckbox && (
          <TableCell padding="checkbox">
            <Checkbox
              checked={!isLoading && isSelectAllChecked()}
              onChange={handleSelectAllClick}
              color="primary"
              inputProps={{ 'aria-label': 'select all items' }}
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell
            key={column.field}
            align={column.align}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy.toString() === column.field.toString() ? order : false}
          >
            {column.disableSorting ? (
              column.name
            ) : (
              <TableSortLabel
                active={orderBy.toString() === column.field.toString()}
                direction={orderBy.toString() === column.field.toString() ? order : 'asc'}
                onClick={createSortHandler(column.field)}
              >
                {column.name}
                {orderBy.toString() === column.field.toString() ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {withActions && <TableCell align="right">{t('Actions')}</TableCell>}
      </TableRow>
    </TableHead>
  )
}

export default EnhancedTableHead
