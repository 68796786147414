import { Box, Link, Tooltip } from '@mui/material'
import { CDN_URL } from 'config'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { countries } from 'utils/countries'
import { getFlagCodepointByIso2 } from 'views/Calendar/components/ReservationDialog/components/ReservationDetails/helpers'

const LinkValue = ({ isPhone = false, to = null, children: value }) => {
  const { pathname } = useLocation()
  const parsedPhoneNumber = isPhone && parsePhoneNumberFromString(value)

  return !isPhone ? (
    <Link component={RouterLink} to={`${pathname}/${to}`}>
      {value}
    </Link>
  ) : (
    <Box display="flex" alignItems="center" gap={0.5}>
      {parsedPhoneNumber && parsedPhoneNumber.country && (
        <Tooltip title={countries[parsedPhoneNumber.country]} arrow placement="top">
          <img
            loading="lazy"
            width={16}
            height={16}
            src={`${CDN_URL}/assets/emojis/${getFlagCodepointByIso2(parsedPhoneNumber.country.toLowerCase())}.svg`}
            alt={''}
          />
        </Tooltip>
      )}
      <Link href={`tel: ${value}`}>{value}</Link>{' '}
    </Box>
  )
}

export default LinkValue
