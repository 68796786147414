import { useState, memo } from 'react'
import { makeStyles } from '@mui/styles'
import { TableRow, TableContainer, TablePagination, TableBody, Table, Typography } from '@mui/material'
import { getComparator, stableSort } from './functions'
import { TableHead } from './components'
import { useTranslation } from 'react-i18next'
import { useUserPreferences } from 'core'
import { PAGE_SIZES } from 'config'
import { alpha } from 'utils'
import { CustomPaperComponent } from 'components'
import { TableRowContent, TableToolbar } from '../Table/components'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  row: {
    borderBottom: '1px solid rgba(81, 81, 81, 0.33) !important',
  },
  selectedRow: {
    backgroundColor:
      theme.palette.mode === 'light' ? `${theme.palette.grey[200]} !important` : `rgba(255, 255, 255, 0.10) !important`,
  },
  hover: {
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.grey[500], 0.08)} !important`,
    },
  },
}))

const EnhancedTable = ({
  columns,
  order: orderProp = 'desc',
  orderBy: orderByProp = 'id',
  rows,
  size = 'small',
  hideToolbar = false,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { pageSize: rowsPerPage, changePageSize } = useUserPreferences()

  const [order, setOrder] = useState(orderProp)
  const [orderBy, setOrderBy] = useState(orderByProp)
  const [page, setPage] = useState(0)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    changePageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <div className={classes.root}>
      <CustomPaperComponent className={classes.paper}>
        {!hideToolbar && <TableToolbar />}
        <TableContainer sx={hideToolbar ? { pt: 1 } : {}}>
          <Table className={classes.table} size={size}>
            <TableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index} classes={{ hover: classes.hover }}>
                      {columns.map((column) => (
                        <TableRowContent key={column.name} column={column} row={row} />
                      ))}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={PAGE_SIZES}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => (
            <Typography color="textSecondary" variant="caption">
              {t('ProductRange', { start: from, end: to, totalProducts: count })}
            </Typography>
          )}
          labelRowsPerPage={
            <Typography color="textSecondary" variant="caption">
              {t('RowsPerPage')}:
            </Typography>
          }
          slotProps={{ select: { className: classes.select } }}
        />
      </CustomPaperComponent>
    </div>
  )
}

export default memo(EnhancedTable)
