const incrementCodepoint = (codePoint, incrementBy) => {
  const decimal = parseInt(codePoint, 16)
  return Number(decimal + incrementBy).toString(16)
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'

const A_LETTER_CODEPOINT = '1f1e6'
const codepoints = alphabet.split('').reduce((obj, currentLetter, index) => {
  return {
    ...obj,
    // construct alphabet by incrementing A letter codepoint value
    [currentLetter]: incrementCodepoint(A_LETTER_CODEPOINT, index),
  }
}, {})

/**
 * Flag emoji value contains 2 emojis which represents iso2 code
 * For example: '🇺🇦' -> U+1F1FA (U letter), U+1F1E6 (A letter)
 * For Twemoji we need to pass codepoints in format `1f1fa-1f1e6`
 */
export const getFlagCodepointByIso2 = (iso2) => {
  return [codepoints[iso2[0]], codepoints[iso2[1]]].join('-')
}
