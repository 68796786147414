import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  DialogTitle,
  Box,
  Alert,
  InputAdornment,
  MenuItem,
  Stack,
} from '@mui/material'
import { PAYMENT_METHODS } from 'config'
import { TRANSACTION_TYPES } from 'config'
import { API_URL } from 'config'
import { PAYMENT_STATUSES } from 'config'
import { useCalendar, useEscapeRooms, useTenantInfo } from 'core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { transformOrderEntriesToCartObject } from 'utils'
import { usePricing } from 'utils/hooks'
import { Summary } from 'views/Calendar/components/Reservations/components/Reservation/components/Status/components/PlayedReservationDialog/components'

const SetAmountDialog = ({
  dialogProps,
  handleCloseMainMenu = () => {},
  handleCloseDialog = () => {},
  reservation,
  escapeRoom,
  isCalendarView = true,
  setIsUpdated = () => {},
}) => {
  const { t } = useTranslation('reservations')
  const { changeReservationStatus } = useEscapeRooms()
  const { loadCalendar } = useCalendar()
  const { flattenedProducts, activeTenantFullInfo } = useTenantInfo()

  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.NONE.key)

  const { data: giftCard, isLoading: isValidateLoading } = useSWR(
    dialogProps.open &&
      dialogProps.type === PAYMENT_STATUSES.PAID_AT_VENUE.key &&
      reservation.discountCode &&
      `${API_URL}/voucher/byCode/${reservation.discountCode}/${escapeRoom.tenantId}`,
    { shouldRetryOnError: false }
  )

  const { data: orderData } = useSWR(
    dialogProps.open &&
      `${API_URL}/${escapeRoom.tenantId}/order-history/${escapeRoom.id}/${reservation.key.reservationId}`,
    { shouldRetryOnError: false, fallbackData: [] }
  )

  const cart = transformOrderEntriesToCartObject(orderData?.orderEntries ?? [], flattenedProducts)

  const {
    balanceDue,
    subtotal,
    validProducts,
    selectedGameMode,
    existingFinances,
    isFinancesLoading,
    existingFinancesRaw,
  } = usePricing({
    cart,
    reservation,
    giftCard,
    numberOfPlayers: reservation.playerCount,
  })

  const [amount, setAmount] = useState(
    dialogProps.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key ? escapeRoom.depositAmount : balanceDue
  )

  useEffect(() => {
    if (dialogProps.open) {
      setAmount(
        dialogProps.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key ? escapeRoom.depositAmount : balanceDue
      )
    }
    // eslint-disable-next-line
  }, [balanceDue, dialogProps.open, dialogProps.type])

  const handleClose = () => {
    handleCloseDialog()
    setAmount(null)
  }

  return (
    <Dialog open={dialogProps.open} onClose={handleCloseDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        {dialogProps.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key ? t('SetDepositAmount') : t('SetAmount')}
      </DialogTitle>
      <DialogContent sx={{ pt: '8px !important' }}>
        {dialogProps.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key &&
          !isFinancesLoading &&
          existingFinancesRaw?.some((finance) => finance.transactionType === TRANSACTION_TYPES['FULL_PAYMENT'].key) && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              {t('FullPaymentFoundInExistingFinances')}
            </Alert>
          )}
        {dialogProps.type === PAYMENT_STATUSES.PAID_AT_VENUE.key && (
          <Alert severity="info">{t('FullPaymentDisclaimer')}</Alert>
        )}
        {dialogProps.type === PAYMENT_STATUSES.PAID_AT_VENUE.key && (
          <Box mb={2}>
            <Summary
              {...{
                balanceDue,
                subtotal,
                cart,
                escapeRoom,
                giftCard,
                isValidateLoading,
                selectedGameMode,
                validProducts,
                reservation,
                existingFinances,
                selectedPlayers: reservation.playerCount,
              }}
            />
          </Box>
        )}
        <Stack gap={1.5}>
          <TextField
            label={
              dialogProps.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key
                ? t('escaperooms:DepositAmount')
                : t('finances:Amount')
            }
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            fullWidth
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">{activeTenantFullInfo?.currency}</InputAdornment>,
            }}
          />
          {amount <
            (dialogProps.type === PAYMENT_STATUSES.PARTIALLY_PAID_AT_VENUE.key
              ? escapeRoom.depositAmount
              : balanceDue) && (
            <Alert sx={{ mt: 2 }} severity="warning">
              {t('AmountIsLessThanTotal')}
            </Alert>
          )}

          <TextField
            fullWidth
            label={t('finances:PaymentMethod')}
            onChange={(e) => setPaymentMethod(e.target.value)}
            value={paymentMethod}
            inputProps={{
              'aria-label': 'payment method',
            }}
            select
          >
            {Object.values(PAYMENT_METHODS).map(({ key, value }) => (
              <MenuItem key={key} value={key}>
                {t(`finances:${value}`)}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClose()
          }}
        >
          {t('translation:Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={async () => {
            handleClose()
            handleCloseMainMenu()
            await changeReservationStatus({
              reservationId: reservation.key.reservationId,
              roomId: reservation.key.roomId,
              paymentStatus: dialogProps.type,
              paymentMethod,
              amount,
            })
            isCalendarView && (await loadCalendar())
            setIsUpdated()
          }}
        >
          {t('translation:Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SetAmountDialog
